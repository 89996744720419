import { Component } from 'react';
import './login.scss';
import illustration from '../../assets/login-pic.svg'
import logo from '../../assets/Logo.svg'
import { forgot, login, reset } from '../../api/api';
import NotificationManager from 'react-notifications/lib/NotificationManager';

class Login extends Component {
  constructor (props) {
    super(props);
    this.state = {
      view: 'login',
      username: '',
      password: ''
    }
    this.login = this.login.bind(this)
  }

  resetView () {
    this.setState({view: 'reset'})
  }
  loginView () {
    this.setState({view: 'login'})
  }
  tokenView () {
    this.setState({view: 'reset-token'})
  }

  async login (event) {
    event.preventDefault();
    login({
      'email': this.state.username,
      'password': this.state.password
    }).then(res => {
      if (res.status === 200) {
        return res.json().then(res => {
          localStorage.setItem("user", JSON.stringify(res.user))
          localStorage.setItem("accessToken", res.tokens.access.token)
          localStorage.setItem("reloginAt", res.tokens.access.expires)
          localStorage.setItem("refreshToken", res.tokens.refresh.token)
          localStorage.setItem("refreshAt", res.tokens.refresh.expires)
          this.props.login()
        })
      } else {
        document.querySelectorAll('#username, #password').forEach(elem => {
          elem.classList.add('invalid')
          elem.setCustomValidity('Ungültige Zugangsdaten')
          elem.reportValidity()
        })
      }
    }).catch(error => {
      console.error(error)
    })
    return false
  }

  async forgot (event) {
    event.preventDefault()
    forgot({
      'email': this.state.mail
    }).then(res => {
      if (res.status === 204) {
        this.setState({view: 'reset-token'})
        return
      } else {
        return res.json().then(res => {
          document.querySelectorAll('#mail').forEach(elem => {
            elem.classList.add('invalid')
            elem.setCustomValidity(res.message)
            elem.reportValidity()
          })
        })
      }
    }).catch(error => {
      console.error(error)
    })
  }

  async reset (event) {
    event.preventDefault()
    if (this.state.newPassword1 !== this.state.newPassword2) {
      document.querySelectorAll('#new-password-1, #new-password-2').forEach(elem => {
        elem.classList.add('invalid')
        elem.setCustomValidity('Die Passwörter stimmen nicht überein.')
        elem.reportValidity()
      })
      return
    }
    reset(this.state.token, this.state.newPassword1).then(res => {
      if (res.status === 204) {
        this.setState({view: 'login'})
        return
      } else {
        return res.json().then(res => {
          console.log(res)
          if (res.validation) {
            for (const e of res.validation) {
              document.querySelectorAll(`#${e.context.key}, .${e.context.key}`).forEach(elem => {
                elem.classList.add('invalid')
                elem.setCustomValidity(e.message)
                elem.reportValidity()
              })
            }
          } else {
            NotificationManager.error(res.message, 'Fehler', 10000)
          }
        })
      }
    }).catch(e => {
      console.error(e)
    })
  }

  render () {
      return (
        <div className="Login">
          <div className="login-image">
            <img src={illustration} alt="" />
            <div className="powered-by">powered by <img src={logo} alt="Codemeta" /></div>
          </div>
          <div className="login-form">
            { this.state.view === 'login' ? 
              <form className="login-wrapper" onSubmit={(e) => {this.login(e)}}>
                <h1>Login</h1>
                  <label htmlFor="username">E-Mail</label>
                  <input type="text" id="username" name="username" placeholder="max@musterfirma.de" onChange={(e) => {this.setState({username: e.target.value})}} />
                  <label htmlFor="password">Passwort</label>
                  <input type="password" id="password" name="password" placeholder="abc1234" onChange={(e) => {this.setState({password: e.target.value})}} />
                  <button onClick={(e) => {this.login(e)}} className="login-button">Login</button>
                  <div className="forgot-password">Passwort vergessen? <button href="#" onClick={(e) => {e.preventDefault(); this.resetView()}} className="reset-switch">Reset</button></div>
              </form>
            : null }
            { this.state.view === 'reset' ?
              <form className="reset-wrapper" onSubmit={(e) => {this.reset(e)}}>
                <h1>Passwort-Reset</h1>
                <label htmlFor="mail">E-Mail</label>
                <input type="text" id="mail" name="mail" placeholder="max@musterfirma.de" onChange={(e) => {this.setState({mail: e.target.value})}} />
                <button onClick={(e) => {this.forgot(e)}} className="reset-button">Reset</button>
                <div className="back-to-login">Wieder eingefallen? <button href="#" onClick={(e) => {e.preventDefault(); this.loginView()}} className="login-switch">Zum Login</button></div>
                <div className="enter-token">Code erhalten? <button href="#" onClick={(e) => {e.preventDefault(); this.tokenView()}} className="token-switch">Hier eingeben</button></div>
              </form>
            : null }
            { this.state.view === 'reset-token' ?
              <form className="reset-wrapper" onSubmit={(e) => {this.reset(e)}}>
                <h1>Passwort-Reset</h1>
                <label htmlFor="token">Reset-Code</label>
                <input type="text" id="token" name="token" autoComplete="one-time-code" placeholder="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI3OGpsSDIzNzBkbDJiMDgyYiIsImlhdCI6MTYxMDEzMzIxMywiZXhwIjoxNjEwMTMzODEzLCJ0eXBlIjoicmVzZXRQYXNzd29yZCJ9.miV-WrjMP4t69HkY-6SqsP7eDyg6nKNVupWkZni8mcE" onChange={(e) => {this.setState({token: e.target.value})}} />
                <input type="password" className="password" id="new-password-1" name="new-password-1" autoComplete="new-password" placeholder="abc1234" onChange={(e) => {this.setState({newPassword1: e.target.value})}} />
                <input type="password" className="password" id="new-password2" name="new-password2" autoComplete="new-password" placeholder="abc1234" onChange={(e) => {this.setState({newPassword2: e.target.value})}} />
                <button onClick={(e) => {this.reset(e)}} className="reset-button">Reset</button>
                <div className="back-to-reset">Doch nicht? <button href="#" onClick={(e) => {e.preventDefault(); this.resetView()}} className="reset-switch">Zurück</button></div>
              </form>
            : null }
          </div>
        </div>
      );
  }
}

export default Login;

import React, { Component } from 'react';
import './messages.scss';
import { getLogs } from '../../api/api'

class Messages extends Component {
  constructor (props) {
    super(props);
    this.state = {
      list: [],
      page: 0,
      pageLength: null,
      open: null,
      apiPage: 0,
      apiPages: null,
      level: 'info'
    }
  }

  componentDidMount () {
    var style = window.getComputedStyle(document.querySelector('.Messages ul'), null);
    const height = parseFloat(style.getPropertyValue('height'))- (parseFloat(style.getPropertyValue('padding-top')) + parseFloat(style.getPropertyValue('padding-bottom')));
    this.setState({
      pageLength: Math.floor(height / 60)
    }, this.loadLogs)
    window.setInterval(() => {
      this.changeLevel(this.state.level)
    }, 5*60*1000)
  }

  loadLogs() {
    getLogs(this.state.level, this.state.apiPage + 1).then(res => {
      if (res.results) {
        this.setState({
          apiPage: res.page,
          apiPages: res.totalPages,
          list: this.state.list.concat(res.results.map(result => {
            const date = new Date(result.time)
            result.time = `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
                          }.${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
                          }.${date.getFullYear()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
                          }:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
            return result
          }))
        })
      } else {
        console.error(res)
      }
    })
  }

  async changeLevel (level) {
    await this.setState({level, list: [], apiPage: 0, apiPages: null, page: 0})
    this.loadLogs()
  }

  next () {
    if (this.state.page === (Math.ceil((this.state.list.length / this.state.pageLength)) - 3) || (this.state.list.length / this.state.pageLength) < 3) {
      if (!this.state.apiPages || this.state.apiPage < this.state.apiPages) {
        this.loadLogs()
      }
    }
    this.setState({page: this.state.page < ((this.state.list.length / this.state.pageLength) - 1) ? this.state.page + 1 : this.state.page})
  }

  render () {
    let items = this.state.list.slice(this.state.page * this.state.pageLength, (this.state.page + 1) * this.state.pageLength).map(msg => {
      return (
        <li key={msg.id} onClick={() => this.setState({open: msg})} className={msg.level}>
          <span className={`${msg.level} icon`} title={msg.level}></span>
          <div>
            <span className="short">{msg.short}</span>
            <span className="details">{msg.details}</span>
          </div>
          <span className="time">{msg.time}</span>
          <span className="cause">{msg.cause || 'Allgemein'}</span>
        </li>
      )
    })
    return (
      <div className="Messages tile">
        <div className={`header ${this.state.open ? 'open' : ''}`}>
          <h3 onClick={() => this.setState({open: null})} className={this.state.open ? 'close-msg' : ''}>Meldungen</h3>
          {this.state.open ? 
            <div>
              <h4>{this.state.open.short}</h4>
              <span className="time">{this.state.open.time}</span>
              <span className="cause">{this.state.open.cause || 'Allgemein'}</span>
            </div>
          : null}
          <select className="levelSelect" onChange={(e) => {this.changeLevel(e.target.value)}} defaultValue={this.state.level}>
            <option value="debug">Debug</option>
            <option value="info">Info</option>
            <option value="notice">Bemerkung</option>
            <option value="warn">Warnung</option>
            <option value="error">Fehler</option>
            <option value="crit">Kritisch</option>
            <option value="alert">Alarm</option>
            <option value="emerg">Katastrophe</option>
          </select>
        </div>
        {!this.state.open ? 
          <ul className="message-list">
            {items}
          </ul>
        : null}
        {!this.state.open ? 
          <div className="button-wrapper">
            {this.state.page > 0 ?
              <button className="back" onClick={() => {this.setState({page: this.state.page > 0 ? this.state.page - 1 : 0})}}>Neuere</button>
            : null }
            {this.state.page < ((this.state.list.length / this.state.pageLength) - 1) ?
              <button className="next" onClick={() => {this.next()}}>Ältere</button>
            : null}
          </div>
        : null}
        {this.state.open ? 
            <div className="info">
              <span className="text">{this.state.open.details}</span>
              {this.state.open.data ? 
                <span className="json code">{JSON.stringify(this.state.open.data, null, 2)}</span>
              : null }
            </div>
          : null}
      </div>
    );
  }
}

export default Messages;

import { Component } from 'react';
import './tagInput.scss';

class TagInput extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tags: [],
      fresh: true
    }
  }

  componentDidMount () {
    this.setState({tags: this.props.value || []})
  }

  componentDidUpdate () {
    if (this.state.fresh && this.props.value) {
      this.setState({tags: this.props.value || [], fresh: false})
    }
  }

  add (evt) {
    if ((!evt.code || evt.code === 'Enter') && evt.target.value) {
      this.setState({
        tags: this.state.tags.concat([evt.target.value])
      }, () => {
        evt.target.value = ''
        this.props.onChange({
          target: {
            id: this.props.id,
            value: this.state.tags
          }
        })
      })
    } else if (evt.code === 'Backspace' && evt.target.value === '') {
      this.state.tags.splice(this.state.tags.length - 1, 1);
      this.setState({
        tags: this.state.tags
      }, () => {
        this.props.onChange({
          target: {
            id: this.props.id,
            value: this.state.tags
          }
        })
      })
    }
  }

  remove (evt, tag) {
    if (evt.target.classList.contains('remove')) {
      const index = this.state.tags.indexOf(tag)
      this.state.tags.splice(index, 1);
      this.setState({tags: this.state.tags}, () => {
        this.props.onChange({
          target: {
            id: this.props.id,
            value: this.state.tags
          }
        })
      })
    }
  }

  render () {
    const tags = this.state.tags.map(tag => {
      return <span className="tag" key={tag} onClick={evt => this.remove(evt, tag)}>{tag}<span className="remove">x</span></span>
    })
    return (
      <div className="TagInput">
        <span className="tags">
          {tags}
        </span>
        <input type="text" id={this.props.id} name={this.props.name} placeholder="Schlagwort hinzufügen..." onKeyDown={(evt) => this.add(evt)} onBlur={(evt) => this.add(evt)} />
      </div>
    );
  }
}

export default TagInput;
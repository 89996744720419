import { Component } from 'react';
import './confirmation.scss';

class Confirmation extends Component {
  constructor (props) {
    super(props);
    this.state = {
      phrases: [
        'Ich weiß, was ich tue',
        'Mach schon!',
        'Aktion bestätigen'
      ]
    }
    if (Array.isArray(this.props.phrase)) {
      this.state.phrase = this.props.phrase[Math.floor(Math.random() * this.props.phrase.length)]
    } else {
      this.state.phrase = this.props.phrase || this.state.phrases[Math.floor(Math.random() * this.state.phrases.length)]
    }
  }

  ok () {
    const input = document.querySelector('.confirmation-input')
    if (input.value.toLowerCase() === this.state.phrase.toLowerCase()) {
      this.props.ok()
    } else {
      input.setCustomValidity('Die Phrase ist inkorrekt')
      input.reportValidity()
    }
  }

  render () {
    return (
      <div className="Confirmation confirmation-overlay">
        <div className="confirmation-box">
          <span className="confirmation-title">{this.props.title || 'Aktion bestätigen'}</span>
          <span className="confirmation-text">{this.props.text || 'Bitte bestätigen Sie die Aktion, indem Sie folgende Phrase eingeben:'}</span>
          <code className="confirmation-phrase">{this.state.phrase}</code>
          <input type="text" className="confirmation-input" placeholder={this.state.phrase} onKeyPress={(evt) => {if (evt.key === 'Enter') this.ok()}}/>
          <span className="confirmation-buttons">
            <button className="confirmation-cancel" onClick={() => this.props.cancel()}>Abbrechen</button>
            <button className="confirmation-ok" onClick={() => this.ok()}>Bestätigen</button>
          </span>
        </div>
      </div>
    );
  }
}

export default Confirmation;
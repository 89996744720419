import { Component } from 'react';
import { getUsers, deleteUser } from '../../../api/api';
import './users.scss';
import { NotificationManager } from 'react-notifications';
import remove from '../../../assets/iconmonstr-x-mark-1.svg';
import edit from '../../../assets/iconmonstr-pencil-16.svg';
import UserInterface from '../../../components/Users/UserInterface'

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: null,
      users: [],
      pageSize: 12,
      role: 'user'
    }
  }

  componentDidMount() {
    this.page(1)
  }

  async page(page) {
    if (page < 1) return
    if (this.state.totalPages && page > this.state.totalPages) return

    const users = await getUsers(page, this.state.pageSize, this.state.filter, this.state.role, this.state.noAdmin)
    console.log(users)
    this.setState({ users: users.results, totalResults: users.totalResults, totalPages: users.totalPages, page })
  }

  refresh() {
    return this.page(this.state.page)
  }

  async deleteUser(id) {
    deleteUser(id).then(res => {
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler beim Löschen', 5000)
    })
  }

  openUserInterface(user) {
    this.setState({
      userInterface: <UserInterface user={user}
      cancel={() => { this.setState({ userInterface: null }); this.refresh() }} />
    })
  }

  allChecked() {
    const lis = document.querySelectorAll('li')
    let state = true
    for (const li of lis) {
      if (li.key == null) continue
      state = state && li.classList.contains('checked')
    }
    this.setState({ allChecked: state })
  }

  toggleSelection() {
    const state = this.state.allChecked
    document.querySelectorAll('li').forEach(li => {
      if (state) {
        li.classList.remove('checked')
      } else {
        li.classList.add('checked')
      }
      if (state) {
        li.querySelector('.checkbox').classList.remove('checked')
      } else {
        li.querySelector('.checkbox').classList.add('checked')
      }
    })
    this.setState({ allChecked: !state })
  }

  render() {
    let items = this.state.users.map(user => {
      return (
        <li id={user.id} className=''>
          <span className={`checkbox`} onClick={(evt) => {
            if (evt.target.classList.contains('checked')) {
              evt.target.classList.remove('checked')
            } else {
              evt.target.classList.add('checked')
            }
            if (evt.target.closest('li').classList.contains('checked')) {
              evt.target.closest('li').classList.remove('checked')
            } else {
              evt.target.closest('li').classList.add('checked')
            }
            this.allChecked()
          }}></span>
          <span className="user-details">{user.firstName + " " + user.lastName}</span>
          <span className="user-email">{user.email}</span>
          <span className="role-selection">{user.role === 'user' ? 'Benutzer' : 'Administrator'}</span>
          <span className="change-role" onClick={(evt) => { this.openUserInterface(user) }} title="Benutzer bearbeiten"><img src={edit} alt="Prüfen" /></span>
          <span className="delete-user" onClick={(evt) => { this.deleteUser(user.id) }} title="Löschen"><img src={remove} alt="Löschen" /></span>
        </li>
      )
    })
    return (
      <div className="Users">
        <div className="heading">
          Benutzerverwaltung
        </div>
        <div className="actions">
          <div className="dropdown-button">
            <button className="dropdown-title" onMouseDown={evt => { this.openUserInterface() /* this.createUser("yk.knorr@gmx.net", "3a4a5i2f5c5", "Rolf", "Rofl", "user") */ }}>
              <span className="bold">Benutzer erzeugen</span>
              <span className="description"> </span>
            </button>
          </div>
        </div>
        <div className="list-header">
          <li>
            <span className={`checkbox ${this.state.allChecked ? 'checked' : ''}`} onClick={(evt) => this.toggleSelection()}></span>
            <span className="user-details">Name</span>
            <span className="user-emails" title="Email des Users">Email</span>
            <div className="dropdown-button role-selection" title="Rolle des Users">
              <button onMouseDown={evt => {
                if (evt.target === document.activeElement) {
                  setTimeout(() => evt.target.blur(), 100)
                  this.setState(() => this.page(1))
                }
              }}>
                {this.state.role === 'user' ? 'Users' : 'Admins'}
              </button>
              <div className="dropdown-buttons">
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ role: 'user' }, () => this.page(1)) }}>Users</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ role: 'admin' }, () => this.page(1)) }}>Admins</button>
              </div>
            </div>
            <span className="do-user" title="Aktionen">Aktion</span>
          </li>
        </div>
        <ul className="list">
          {items}
        </ul>
        <div className="navigation">
          <span className="text">
            Einträge {1 + (this.state.page - 1) * this.state.pageSize} bis {
              (this.state.page) * this.state.pageSize < this.state.totalResults ? (this.state.page) * this.state.pageSize : this.state.totalResults
            } von {this.state.totalResults}
          </span>
          <span className="prev-page" onClick={() => this.page(this.state.page - 1)}></span>
          <span className="next-page" onClick={() => this.page(this.state.page + 1)}></span>
        </div>
        {this.state.userInterface}
      </div>
    );
  }
}

export default Users;

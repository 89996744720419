import { Component } from 'react';
import './config.scss';
import tanss from '../../../assets/tanss.svg'
import weclapp from '../../../assets/weclapp.svg'
import smtp from '../../../assets/mail.svg'
import logo from '../../../assets/Logo.svg'
import { getSettings, setSettings, getTaxes } from '../../../api/api';
import { NotificationManager } from 'react-notifications';
import TagInput from '../../../components/Inputs/TagInput';
import SwitchInput from '../../../components/Inputs/SwitchInput';

const illustrations = {
  tanss,
  weclapp,
  smtp
}

class Config extends Component {
  constructor (props) {
    super(props);
    this.state = {
      view: 'invoices',
      settings: {},
      template: 'services',
      datasync: 'general',
      taxes: []
    }
  }

  componentDidMount () {
    getSettings().then(res => {
      const settings = {}
      res.forEach(setting => {
        settings[setting.key] = setting.value
      })
      this.setState({settings})
    })
    getTaxes().then(res => {
      this.setState({taxes: res})
    })
  }

  change (evt) {
    const settings = this.state.settings
    settings[evt.target.id] = evt.target.value
    this.setState({settings})
  }

  async save() {
    const obj = {}
    document.querySelectorAll('.config-form input, .config-form select, .config-form textarea').forEach(element => {
      obj[element.name] = this.state.settings[element.name]
    })
    setSettings(this.state.view, obj).then(res => {
      if (res.status === 204) {
        document.querySelectorAll('.invalid').forEach(elem => {elem.classList.remove('invalid')})
        NotificationManager.success('Einstellungen gespeichert', '', 2000)
        return
      }
      return res.json().then(res => {
        if (res.validation) {
          document.querySelectorAll('.invalid').forEach(elem => {elem.classList.remove('invalid')})
          res.validation.forEach(err => {
            let elem = document.querySelector(`#${err.context.key}`)
            if (elem) {
              elem.classList.add('invalid')
              elem.setCustomValidity(err.message)
              elem.reportValidity()
            }
          })
        }
      })
    }).catch(e => {
      console.error(e)
    })
  }

  render () {
    const taxOptions = this.state.taxes.map(tax => {
      return <option value={tax.id} key={tax.id}>{tax.name}</option>
    })
      return (
        <div className="Config">
          <div className="config-form">
            <h1>Konfiguration</h1>
            <div className="progress-bar">
              <div onClick={() => {this.setState({view: 'tanss'})}} className={`progress-item ${this.state.view === 'tanss' ? 'active' : ''}`}>TANSS</div>
              <div onClick={() => {this.setState({view: 'weclapp'})}} className={`progress-item ${this.state.view === 'weclapp' ? 'active' : ''}`}>WeClapp</div>
              <div onClick={() => {this.setState({view: 'smtp'})}} className={`progress-item ${this.state.view === 'smtp' ? 'active' : ''}`}>E-Mail</div>
              <div onClick={() => {this.setState({view: 'invoices'})}} className={`progress-item ${this.state.view === 'invoices' ? 'active' : ''}`}>Rechnungen</div>
              <div onClick={() => {this.setState({view: 'templates'})}} className={`progress-item ${this.state.view === 'templates' ? 'active' : ''}`}>
                { this.state.view !== 'templates' ? 'Templates' : null }
                { this.state.view === 'templates' && this.state.template === 'services' ? 'Leistungen' : null }
                { this.state.view === 'templates' && this.state.template === 'tickets' ? 'Tickets' : null }
                { this.state.view === 'templates' && this.state.template === 'travel' ? 'Fahrt' : null }
                { this.state.view === 'templates' && this.state.template === 'distance' ? 'Kilometer' : null }
                { this.state.view === 'templates' && this.state.template === 'duration' ? 'Fahrtzeit' : null }
                { this.state.view === 'templates' && this.state.template === 'zone' ? 'Fahrtzonen' : null }
                { this.state.view === 'templates' && this.state.template === 'material' ? 'Material' : null }
                { this.state.view === 'templates' && this.state.template === 'employees' ? 'Techniker' : null }
                <div className="dropdown">
                  { this.state.settings.groupBy !== 'technician' ? 
                    <span className={`${this.state.view === 'templates' && this.state.template === 'services' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'services'})}}>Leistungen</span>
                  : null }
                  <span className={`${this.state.view === 'templates' && this.state.template === 'tickets' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'tickets'})}}>Tickets</span>
                  { this.state.settings.travelMode === 'onePosition' ?
                    <span className={`${this.state.view === 'templates' && this.state.template === 'travel' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'travel'})}}>Fahrten</span>
                  : null }
                  { ['single', 'includeDuration'].indexOf(this.state.settings.travelMode) !== -1  ?
                    <span className={`${this.state.view === 'templates' && this.state.template === 'distance' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'distance'})}}>Kilometer</span>
                  : null }
                  { this.state.settings.travelMode === 'single' ?
                    <span className={`${this.state.view === 'templates' && this.state.template === 'duration' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'duration'})}}>Fahrtzeit</span>
                  : null }
                  { ['single', 'includeDuration'].indexOf(this.state.settings.travelMode) !== -1 ?
                    <span className={`${this.state.view === 'templates' && this.state.template === 'zone' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'zone'})}}>Fahrtzonen</span>
                  : null }
                  { this.state.settings.listMaterial ?
                    <span className={`${this.state.view === 'templates' && this.state.template === 'material' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'material'})}}>Material</span>
                  : null }
                  { this.state.settings.groupBy === 'technician' ?
                    <span className={`${this.state.view === 'templates' && this.state.template === 'employees' ? 'active' : ''}`} onClick={(evt) => {this.setState({template: 'employees'})}}>Techniker</span>
                  : null }
                </div>  
              </div>
              <div onClick={() => {this.setState({view: 'datasync'})}} className={`progress-item ${this.state.view === 'datasync' ? 'active' : ''}`}>
                { this.state.view !== 'datasync' ? 'Stammdaten' : null }
                { this.state.view === 'datasync' && this.state.datasync === 'general' ? 'Allgemein' : null }
                { this.state.view === 'datasync' && this.state.datasync === 'companies' ? 'Firmen' : null }
                { this.state.view === 'datasync' && this.state.datasync === 'contacts' ? 'Kontakte' : null }
                <div className="dropdown">
                  <span className={`${this.state.view === 'datasync' && this.state.datasync === 'general' ? 'active' : ''}`} onClick={(evt) => {this.setState({datasync: 'general'})}}>Allgemein</span>
                  <span className={`${this.state.view === 'datasync' && this.state.datasync === 'companies' ? 'active' : ''}`} onClick={(evt) => {this.setState({datasync: 'companies'})}}>Firmen</span>
                  <span className={`${this.state.view === 'datasync' && this.state.datasync === 'contacts' ? 'active' : ''}`} onClick={(evt) => {this.setState({datasync: 'contacts'})}}>Kontakte</span>
                </div>  
              </div>
            </div>
            { this.state.view === 'tanss' ? 
              <div className="field-wrapper">
                <label htmlFor="TANSS_HOST">TANSS-Host</label>
                <input onChange={evt => this.change(evt)} type="text" id="TANSS_HOST" name="TANSS_HOST" placeholder="tanss.musterdomain.de" defaultValue={this.state.settings.TANSS_HOST} />
                <label htmlFor="TANSS_ERP_KEY">JSON Web Token (ERP-Schnittstelle)</label>
                <input onChange={evt => this.change(evt)} type="text" id="TANSS_ERP_KEY" name="TANSS_ERP_KEY" defaultValue={this.state.settings.TANSS_ERP_KEY} placeholder="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJFUlAiLCJleHAiOjE2MjUxMzQxMjAsInR5cGUiOiJFUlAifQ.tpC2cWRXDfVGRFEYWGwi3FLH-O6f39xIs52-qnM8vJ4" />
                <label htmlFor="TANSS_USERNAME">TANSS Benutzername</label>
                <input onChange={evt => this.change(evt)} type="text" id="TANSS_USERNAME" name="TANSS_USERNAME" placeholder="max@musterfirma.de" autoComplete="username" />
                <label htmlFor="TANSS_PASSWORD">TANSS Passwort</label>
                <input onChange={evt => this.change(evt)} type="password" id="TANSS_PASSWORD" name="TANSS_PASSWORD" placeholder="123456" />
                <label htmlFor="TANSS_OTP">TANSS 2FA-Token</label>
                <input onChange={evt => this.change(evt)} type="text" id="TANSS_OTP" name="TANSS_OTP" placeholder="" inputMode="numeric" autoComplete="one-time-code" />
              </div>
            : null }

            { this.state.view === 'weclapp' ? 
              <div className="field-wrapper">
                <label htmlFor="WECLAPP_HOST">WeClapp-Host</label>
                <input onChange={evt => this.change(evt)} type="text" id="WECLAPP_HOST" name="WECLAPP_HOST" placeholder="firma.weclapp.com" defaultValue={this.state.settings.WECLAPP_HOST} />
                <label htmlFor="WECLAPP_API_KEY">WeClapp-API-Key</label>
                <input onChange={evt => this.change(evt)} type="text" id="WECLAPP_API_KEY" name="WECLAPP_API_KEY" placeholder="c190f73a-e4e3-4d7c-97fd-76405df7b801" defaultValue={this.state.settings.WECLAPP_API_KEY} />
              </div>
            : null }

            { this.state.view === 'smtp' ? 
              <div className="field-wrapper">
                <label htmlFor="SMTP_HOST">SMTP-Host</label>
                <input onChange={evt => this.change(evt)} type="text" id="SMTP_HOST" name="SMTP_HOST" placeholder="smtp.musterdomain.de" defaultValue={this.state.settings.SMTP_HOST} />
                <label htmlFor="SMTP_USERNAME">SMTP-Benutzer</label>
                <input onChange={evt => this.change(evt)} type="text" id="SMTP_USERNAME" name="SMTP_USERNAME" placeholder="max@musterdomain.de" defaultValue={this.state.settings.SMTP_USERNAME} />
                <label htmlFor="SMTP_PASSWORD">SMTP-Passwort</label>
                <input onChange={evt => this.change(evt)} type="password" id="SMTP_PASSWORD" name="SMTP_PASSWORD" placeholder="lol123" defaultValue={this.state.settings.SMTP_PASSWORD} />
                <label htmlFor="EMAIL_FROM">Absender-Adresse</label>
                <input onChange={evt => this.change(evt)} type="text" id="EMAIL_FROM" name="EMAIL_FROM" placeholder="max@musterdomain.de" defaultValue={this.state.settings.EMAIL_FROM} />
              </div>
            : null }

            { this.state.view === 'invoices' ? 
              <div className="field-wrapper">
                <label htmlFor="groupBy">Abrechnungsmodus</label>
                <select onChange={evt => this.change(evt)} id="groupBy" name="groupBy" value={this.state.settings.groupBy}>
                  <option value="none">Leistungen</option>
                  <option value="ticket">Tickets</option>
                  <option value="technician">Techniker</option>
                </select>
                <label htmlFor="defaultUser">Standardbenutzer</label>
                <input onChange={evt => this.change(evt)} type="text" id="defaultUser" name="defaultUser" placeholder="max.mustermann@musterfirma.de" defaultValue={this.state.settings.defaultUser} />
                <label htmlFor="ticketArticle">Ticket-Artikel</label>
                <input onChange={evt => this.change(evt)} type="text" id="ticketArticle" name="ticketArticle" defaultValue={this.state.settings.ticketArticle} placeholder="Ticket" />
                <label htmlFor="listMaterial">Materialabrechnung</label>
                <select onChange={evt => this.change(evt)} id="listMaterial" name="listMaterial" value={this.state.settings.listMaterial}>
                  <option value="true">Aktiviert</option>
                  <option value="false">Deaktiviert</option>
                </select>
                <label htmlFor="listMisc">Nicht kategorisierte Positionen</label>
                <select onChange={evt => this.change(evt)} id="listMisc" name="listMisc" value={this.state.settings.listMisc}>
                  <option value="true">Auflisten</option>
                  <option value="false">Nicht auflisten</option>
                </select>
                <label htmlFor="listUnbillable">Nicht berechnete Leistungen</label>
                <select onChange={evt => this.change(evt)} id="listUnbillable" name="listUnbillable" value={this.state.settings.listUnbillable}>
                  <option value="true">Auflisten</option>
                  <option value="false">Nicht auflisten</option>
                </select>
                <label htmlFor="travelMode">Fahrtabrechnung</label>
                <select onChange={evt => this.change(evt)} id="travelMode" name="travelMode" value={this.state.settings.travelMode}>
                  <option value="include">Nicht einzeln ausweisen</option>
                  <option value="onePosition">Alle Fahrten in einer Position</option>
                  <option value="includeDuration">Nur Kilometer und Zone ausweisen</option>
                  <option value="single">Alles einzeln ausweisen</option>
                </select>
                <label htmlFor="travelInvoicePosition">Fahrtpositionen</label>
                <select onChange={evt => this.change(evt)} id="travelInvoicePosition" name="travelInvoicePosition" value={this.state.settings.travelInvoicePosition}>
                  <option value="withService">Nach zugehöriger Leistung auflisten</option>
                  <option value="bottom">Am Ende auflisten</option>
                </select>
                { ['single', 'includeDuration'].indexOf(this.state.settings.travelMode) !== -1 ?
                  <div>
                    <label htmlFor="distanceArticle">Kilometer-Artikel</label>
                    <input onChange={evt => this.change(evt)} type="text" id="distanceArticle" name="distanceArticle" placeholder="Kilometer" defaultValue={this.state.settings.distanceArticle} />
                  </div>
                : null }
                { this.state.settings.travelMode === 'single' ?
                  <div>
                    <label htmlFor="durationArticle">Fahrtzeit-Artikel</label>
                    <input onChange={evt => this.change(evt)} type="text" id="durationArticle" name="durationArticle" placeholder="Fahrtzeit" defaultValue={this.state.settings.durationArticle} />
                  </div>
                : null }
                { this.state.settings.travelMode === 'onePosition' ?
                  <div>
                    <label htmlFor="travelArticle">Fahrt-Artikel</label>
                    <input onChange={evt => this.change(evt)} type="text" id="travelArticle" name="travelArticle" placeholder="Fahrt" defaultValue={this.state.settings.travelArticle} />
                  </div>
                : null }
                <label htmlFor="billTravelDuration">Fahrtzeit berechnen <a href="https://t2w.codemeta.de/fahrtzeit-bug/">(ACHTUNG Tanss Bug!)</a></label>
                <SwitchInput onChange={evt => this.change(evt)} id="billTravelDuration" name="billTravelDuration" value={this.state.settings.billTravelDuration} placeholder="" />
                <label htmlFor="recordOpening">Kopftext</label>
                <input onChange={evt => this.change(evt)} type="text" id="recordOpening" name="recordOpening" defaultValue={this.state.settings.recordOpening} placeholder="" />
                <label htmlFor="commission">Kommission</label>
                <input onChange={evt => this.change(evt)} type="text" id="commission" name="commission" defaultValue={this.state.settings.commission} placeholder="" />
                <label htmlFor="tags">Tags</label>
                <TagInput onChange={evt => this.change(evt)} id="tags" name="tags" value={this.state.settings.tags} placeholder="" />
                <label htmlFor="EUTaxModel">Steuermodell für ausländische Kunden</label>
                <select onChange={evt => this.change(evt)} id="EUTaxModel" name="EUTaxModel" value={this.state.settings.EUTaxModel}>
                  {taxOptions}
                </select>
                <label htmlFor="loadVouchers">Belege automatisch abfragen</label>
                <SwitchInput onChange={evt => this.change(evt)} id="loadVouchers" name="loadVouchers" value={this.state.settings.loadVouchers} placeholder="" />
                <label htmlFor="autoGenerate">Rechnungen automatisch generieren</label>
                <SwitchInput onChange={evt => this.change(evt)} id="autoGenerate" name="autoGenerate" value={this.state.settings.autoGenerate} placeholder="" />
              </div>
            : null }
            { this.state.view === 'templates' && this.state.template === 'services' ? 
              <div className="field-wrapper">
                <label htmlFor="serviceTitle">Positionstitel</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="serviceTitle" name="serviceTitle" defaultValue={this.state.settings.serviceTitle} ></textarea>
                <label htmlFor="serviceDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="serviceDescription" name="serviceDescription" defaultValue={this.state.settings.serviceDescription} ></textarea>
                <label htmlFor="serviceGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="serviceGroup" name="serviceGroup" defaultValue={this.state.settings.serviceGroup} ></textarea>
                <label htmlFor="serviceUnit">Einheit</label>
                <select onChange={evt => this.change(evt)} id="serviceUnit" name="serviceUnit" value={this.state.settings.serviceUnit}>
                  <option value="ae">Abrechnungseinheiten</option>
                  <option value="h">Stunden</option>
                  <option value="min">Minuten</option>
                </select>
              </div>
            : null }
            
            { this.state.view === 'templates' && this.state.template === 'tickets' ? 
              <div className="field-wrapper">
                <label htmlFor="ticketTitle">Positionstitel</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="ticketTitle" name="ticketTitle" defaultValue={this.state.settings.ticketTitle} ></textarea>
                <label htmlFor="ticketDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="ticketDescription" name="ticketDescription" defaultValue={this.state.settings.ticketDescription} ></textarea>
                <label htmlFor="ticketGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="ticketGroup" name="ticketGroup" defaultValue={this.state.settings.ticketGroup} ></textarea>
              </div>
            : null }
            
            { this.state.view === 'templates' && this.state.template === 'travel' ? 
              <div className="field-wrapper">
                <label htmlFor="travelTitle">Positionstitel</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="travelTitle" name="travelTitle" defaultValue={this.state.settings.travelTitle} ></textarea>
                <label htmlFor="travelDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="travelDescription" name="travelDescription" defaultValue={this.state.settings.travelDescription} ></textarea>
                <label htmlFor="travelGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="travelGroup" name="travelGroup" defaultValue={this.state.settings.travelGroup} ></textarea>
              </div>
            : null }
            
            { this.state.view === 'templates' && this.state.template === 'distance' ? 
              <div className="field-wrapper">
                <label htmlFor="distanceTitle">Positionstitel</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="distanceTitle" name="distanceTitle" defaultValue={this.state.settings.distanceTitle} ></textarea>
                <label htmlFor="distanceDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="distanceDescription" name="distanceDescription" defaultValue={this.state.settings.distanceDescription} ></textarea>
                <label htmlFor="distanceGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="distanceGroup" name="distanceGroup" defaultValue={this.state.settings.distanceGroup} ></textarea>
              </div>
            : null }
            
            { this.state.view === 'templates' && this.state.template === 'duration' ? 
              <div className="field-wrapper">
                <label htmlFor="durationTitle">Positionstitel</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="durationTitle" name="durationTitle" defaultValue={this.state.settings.durationTitle} ></textarea>
                <label htmlFor="durationDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="durationDescription" name="durationDescription" defaultValue={this.state.settings.durationDescription} ></textarea>
                <label htmlFor="durationGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="durationGroup" name="durationGroup" defaultValue={this.state.settings.durationGroup} ></textarea>
              </div>
            : null }
            
            { this.state.view === 'templates' && this.state.template === 'zone' ? 
              <div className="field-wrapper">
                <label htmlFor="zoneTitle">Positionstitel</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="zoneTitle" name="zoneTitle" defaultValue={this.state.settings.zoneTitle} ></textarea>
                <label htmlFor="zoneDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="zoneDescription" name="zoneDescription" defaultValue={this.state.settings.zoneDescription} ></textarea>
                <label htmlFor="zoneGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="zoneGroup" name="zoneGroup" defaultValue={this.state.settings.zoneGroup} ></textarea>
              </div>
            : null }
            
            { this.state.view === 'templates' && this.state.template === 'material' ? 
              <div className="field-wrapper">
                <label htmlFor="materialDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="materialDescription" name="materialDescription" defaultValue={this.state.settings.materialDescription} ></textarea>
                <label htmlFor="materialGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="materialGroup" name="materialGroup" defaultValue={this.state.settings.materialGroup} ></textarea>
              </div>
            : null }
            
            { this.state.view === 'templates' && this.state.template === 'employees' ? 
              <div className="field-wrapper">
                <label htmlFor="employeeTitle">Positionstitel</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="employeeTitle" name="employeeTitle" defaultValue={this.state.settings.employeeTitle} ></textarea>
                <label htmlFor="employeeDescription">Positionsbeschreibung</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor expand" id="employeeDescription" name="employeeDescription" defaultValue={this.state.settings.employeeDescription} ></textarea>
                <label htmlFor="employeeGroup">Positionsgruppe</label>
                <textarea onChange={evt => this.change(evt)} className="template-editor" id="employeeGroup" name="employeeGroup" defaultValue={this.state.settings.employeeGroup} ></textarea>
              </div>
            : null }

            { this.state.view === 'datasync' && this.state.datasync === 'general' ? 
              <div className="field-wrapper">
                <label>Abgleich aktivieren</label>
                <SwitchInput onChange={evt => this.change(evt)} id="activateSync" name="activateSync" value={this.state.settings.activateSync} placeholder="" />
                </div>
            : null }
            
            { this.state.view === 'datasync' && this.state.datasync === 'companies' ? 
              <div className="field-wrapper">
                <label>Firmenname erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="companyNameRequired" name="companyNameRequired" value={this.state.settings.companyNameRequired} placeholder="" />
                <label>Straße erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="companyStreetRequired" name="companyStreetRequired" value={this.state.settings.companyStreetRequired} placeholder="" />
                <label>PLZ erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="companyZipCodeRequired" name="companyZipCodeRequired" value={this.state.settings.companyZipCodeRequired} placeholder="" />
                <label>Ort erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="companyCityRequired" name="companyCityRequired" value={this.state.settings.companyCityRequired} placeholder="" />
                <label>Telefon erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="companyPhoneRequired" name="companyPhoneRequired" value={this.state.settings.companyPhoneRequired} placeholder="" />
                <label htmlFor="travelInvoicePosition">Nebenstellen</label>
                <select onChange={evt => this.change(evt)} id="companyBranches" name="companyBranches" value={this.state.settings.companyBranches}>
                  <option value="sync">Synchronisieren</option>
                  <option value="noSync">Nicht Synchronisieren</option>
                  <option value="asAddress">Als Adresse hinzufügen</option>
                </select>
                <label>Telefon-Regex</label>
                <input onChange={evt => this.change(evt)} type="text" id="companyPhoneRegex" name="companyPhoneRegex" defaultValue={this.state.settings.companyPhoneRegex} placeholder="" />
                <label>E-Mail erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="companyMailRequired" name="companyMailRequired" value={this.state.settings.companyMailRequired} placeholder="" />
                <label>E-Mail-Regex</label>
                <input onChange={evt => this.change(evt)} type="text" id="companyMailRegex" name="companyMailRegex" defaultValue={this.state.settings.companyMailRegex} placeholder="" />
                <label>weclapp-Feld für den Matchcode</label>
                <input onChange={evt => this.change(evt)} type="text" id="matchcodeField" name="matchcodeField" defaultValue={this.state.settings.matchcodeField} placeholder="" />
              </div>
            : null }

            { this.state.view === 'datasync' && this.state.datasync === 'contacts' ? 
              <div className="field-wrapper">
                <label>Anrede synchronisieren</label>
                <SwitchInput onChange={evt => this.change(evt)} id="syncSalutation" name="syncSalutation" value={this.state.settings.syncSalutation} placeholder="" />
                <label>Vorname erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="contactFirstNameRequired" name="contactFirstNameRequired" value={this.state.settings.contactFirstNameRequired} placeholder="" />
                <label>Nachname erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="contactLastNameRequired" name="contactLastNameRequired" value={this.state.settings.contactLastNameRequired} placeholder="" />
                <label>E-Mail erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="contactMailRequired" name="contactMailRequired" value={this.state.settings.contactMailRequired} placeholder="" />
                <label>E-Mail-Regex</label>
                <input onChange={evt => this.change(evt)} type="text" id="contactMailRegex" name="contactMailRegex" defaultValue={this.state.settings.contactMailRegex} placeholder="" />
                <label>Telefon erforderlich</label>
                <SwitchInput onChange={evt => this.change(evt)} id="contactPhoneRequired" name="contactPhoneRequired" value={this.state.settings.contactPhoneRequired} placeholder="" />
                <label>Telefon-Regex</label>
                <input onChange={evt => this.change(evt)} type="text" id="contactPhoneRegex" name="contactPhoneRegex" defaultValue={this.state.settings.contactPhoneRegex} placeholder="" />
              </div>
            : null }
            <div className="button-wrapper">
              <button onClick={() => this.save()} className="next">Speichern</button>
            </div>
            <div className="help">Fragen oder Probleme? <a href="https://t2w.codemeta.de" className="support-button">Support</a></div>
          </div>
          <div className="preview">
            <img src={illustrations[this.state.view]} alt="" />
            <div className="powered-by">powered by <img src={logo} alt="Codemeta" /></div>
          </div>
        </div>
      );
  }
}

export default Config;

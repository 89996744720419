import { Component } from 'react';
import { changeUser, createUser } from '../../api/api';
import './userInterface.scss';

class UserInterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: 'max.mustermann@mustermax.de',
      password: '',
      firstName: 'Max',
      lastName: 'Mustermann',
      role: 'user',
    }
  }

  async create() {
    const ids = ["email", "firstName", "lastName", "password"]
    let complete = true
    for (var i = 0; i < ids.length; i++) {
      if (document.getElementById(ids[i]).value === "") {
        document.getElementById(ids[i]).className = "impossible-input"
        complete = false
      }
      else document.getElementById(ids[i]).className = "user-input"
    }
    if (complete) {
        await this.setState({
          email: document.getElementById(ids[0]).value,
          firstName: document.getElementById(ids[1]).value,
          lastName: document.getElementById(ids[2]).value,
          password: document.getElementById(ids[3]).value,
          role: document.getElementById("role").value
        })
    }
    createUser(this.state.email, this.state.password, this.state.firstName, this.state.lastName, this.state.role).then(res => {
      if (res.status === 201) this.props.cancel()
    }).catch(e => {
    })
  }

  async modify() {
    const ids = ["email", "firstName", "lastName"]
    let complete = true
    for (var i = 0; i < ids.length; i++) {
      if (document.getElementById(ids[i]).value === "") {
        document.getElementById(ids[i]).className = "impossible-input"
        complete = false
      }
      else document.getElementById(ids[i]).className = "user-input"
    }
    if (complete) {
        await this.setState({
          email: document.getElementById(ids[0]).value,
          firstName: document.getElementById(ids[1]).value,
          lastName: document.getElementById(ids[2]).value,
          role: document.getElementById("role").value
        })
    this.props.user.email = this.state.email
    this.props.user.firstName = this.state.firstName
    this.props.user.lastName = this.state.lastName
    this.props.user.role = this.state.role
    changeUser(this.props.user).then(res => {
      if (res.status === 200) this.props.cancel()
    }).catch(e => {
      console.log(e)
    })
  }
  }

  render() {
    return (
      <div className="userInterface userInterface-overlay">
        <div className="userInterface-box">
          <span className="userInterface-title">{this.props.title || 'Benutzer erzeugen'}</span>
          <span className="userInterface-text">Ihre Angaben werden durch {this.props.user? 'Ändern' : 'Erzeugen'} geprüft.</span>
          Email
          <input type="text" id="email" className="user-input" placeholder={this.state.email} defaultValue={this.props.user?.email} />
          Vorname
          <input type="text" id="firstName" className="user-input" placeholder={this.state.firstName} defaultValue={this.props.user?.firstName} />
          Nachname
          <input type="text" id="lastName" className="user-input" placeholder={this.state.lastName} defaultValue={this.props.user?.lastName} />
          {this.props.user? "" : "Passwort"}
          {this.props.user? "" :<input type="password" id="password" className="user-input" />}
          Rolle
          <select onChange={evt => this.setState({ role: evt.target.value })} id="role" className="user-input" defaultValue={this.props.user ? this.props.user.role : this.state.role}>
            <option value="user">Benutzer</option>
            <option value="admin">Admin</option>
          </select>

          <span className="userInterface-buttons">
            <button className="userInterface-cancel" onClick={() => this.props.cancel()}>Abbrechen</button>
            <button className="userInterface-ok" onClick={() => { this.props.user ? this.modify() : this.create() }}>{this.props.user ? "Ändern" : "Erzeugen"}</button>
          </span>
        </div>
      </div>
    );
  }
}

export default UserInterface;
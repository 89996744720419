import { Component } from 'react';
import './install.scss';
import registerIllustration from '../../assets/register.svg'
import tanssIllustration from '../../assets/tanss.svg'
import weclappIllustration from '../../assets/weclapp.svg'
import mailIllustration from '../../assets/mail.svg'
import logo from '../../assets/Logo.svg'
import { register, smtp, tanss, weclapp } from '../../api/api';

const views = [
  'register',
  'tanss',
  'weclapp',
  'mail'
]
const illustrations = {
  register: registerIllustration,
  tanss: tanssIllustration,
  weclapp: weclappIllustration,
  mail: mailIllustration
}

class Install extends Component {
  constructor (props) {
    super(props);
    this.state = {
      view: this.props.loggedIn ? 'tanss' : 'register'
    }
  }

  back () {
    const cur = views.indexOf(this.state.view)
    if (cur > 0) {
      this.setState({
        view: views[cur - 1]
      })
    }
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
  
      reader.onload = () => {
        resolve(reader.result);
      };
  
      reader.onerror = reject;
  
      reader.readAsText(file, "UTF-8");
    })
  }

  next () {
    // Benutzer hinzufügen
    if (this.state.view === 'register') {
      register({
        'company': document.querySelector('#company').value,
        'firstName': document.querySelector('#firstName').value,
        'lastName': document.querySelector('#lastName').value,
        'email': document.querySelector('#email').value,
        'password': document.querySelector('#password').value
      }).then(res => {
        if (res.status === 201) {
          const cur = views.indexOf(this.state.view)
          if (cur < (views.length - 1)) {
            this.setState({
              view: views[cur + 1]
            })
          }
        }
        return res.json()
      }).then(res => {
        if (res.user) {
          localStorage.setItem("user", JSON.stringify(res.user))
          localStorage.setItem("accessToken", res.tokens.access.token)
          localStorage.setItem("refreshAt", res.tokens.access.expires)
          localStorage.setItem("refreshToken", res.tokens.refresh.token)
          localStorage.setItem("reloginAt", res.tokens.refresh.expires)
        } else if (res.validation) {
          document.querySelectorAll('.invalid').forEach(elem => {elem.classList.remove('invalid')})
          res.validation.forEach(err => {
            let elem = document.querySelector(`#${err.context.key}`)
            if (elem) {
              elem.classList.add('invalid')
              elem.setCustomValidity(err.message)
              elem.reportValidity()
            }
          })
        }
      }).catch(e => {
        console.error(e)
      })
    }
    else if (this.state.view === 'tanss') {
      return tanss({
        "TANSS_HOST": document.querySelector('#TANSS_HOST').value,
        "TANSS_ERP_KEY": document.querySelector('#TANSS_ERP_KEY').value,
        "TANSS_USERNAME": document.querySelector('#TANSS_USERNAME').value,
        "TANSS_PASSWORD": document.querySelector('#TANSS_PASSWORD').value,
        "TANSS_OTP": document.querySelector('#TANSS_OTP').value
      }).then(res => {
        if (res.status === 204) {
          const cur = views.indexOf(this.state.view)
          if (cur < (views.length - 1)) {
            this.setState({
              view: views[cur + 1]
            })
          }
          return
        }
        return res.json().then(res => {
          if (res.validation) {
            document.querySelectorAll('.invalid').forEach(elem => {elem.classList.remove('invalid')})
            res.validation.forEach(err => {
              let elem = document.querySelector(`#${err.context.key}`)
              if (elem) {
                elem.classList.add('invalid')
                elem.setCustomValidity(err.message)
                elem.reportValidity()
              }
            })
          }
        })
      }).catch(e => {
        console.error(e)
      })
    }
    else if (this.state.view === 'weclapp') {
      weclapp({
        "WECLAPP_HOST": document.querySelector('#WECLAPP_HOST').value,
        "WECLAPP_API_KEY": document.querySelector('#WECLAPP_API_KEY').value
      }).then(res => {
        if (res.status === 204) {
          const cur = views.indexOf(this.state.view)
          if (cur < (views.length - 1)) {
            this.setState({
              view: views[cur + 1]
            })
          }
          return
        }
        return res.json().then(res => {
          if (res.validation) {
            document.querySelectorAll('.invalid').forEach(elem => {elem.classList.remove('invalid')})
            res.validation.forEach(err => {
              let elem = document.querySelector(`#${err.context.key}`)
              if (elem) {
                elem.classList.add('invalid')
                elem.setCustomValidity(err.message)
                elem.reportValidity()
              }
            })
          }
        })
      }).catch(e => {
        console.error(e)
      })
    }
    else if (this.state.view === 'mail') {
      smtp({
        "SMTP_HOST": document.querySelector('#SMTP_HOST').value,
        "SMTP_USERNAME": document.querySelector('#SMTP_USERNAME').value,
        "SMTP_PASSWORD": document.querySelector('#SMTP_PASSWORD').value,
        "EMAIL_FROM": document.querySelector('#EMAIL_FROM').value
      }).then(res => {
        if (res.status === 204) {
          this.props.done()
          return
        }
        return res.json().then(res => {
          if (res.validation) {
            document.querySelectorAll('.invalid').forEach(elem => {elem.classList.remove('invalid')})
            res.validation.forEach(err => {
              let elem = document.querySelector(`#${err.context.key}`)
              if (elem) {
                elem.classList.add('invalid')
                elem.setCustomValidity(err.message)
                elem.reportValidity()
              }
            })
          }
        })
      }).catch(e => {
        console.error(e)
      })
    }
  }

  render () {
      return (
        <div className="Install">
          <div className="install-image">
            <img src={illustrations[this.state.view]} alt="" />
            <div className="powered-by">powered by <img src={logo} alt="Codemeta" /></div>
          </div>
          <div className="install-form">
              <div className="install-wrapper">
                <h1>Installation</h1>
                <div className="progress-bar">
                  { !this.props.loggedIn ?
                    <div onClick={() => {this.setState({view: 'register'})}} className={`progress-item ${this.state.view === 'register' ? 'active' : ''}`}>Login</div>
                  : null }
                  <div onClick={() => {this.setState({view: 'tanss'})}} className={`progress-item ${this.state.view === 'tanss' ? 'active' : ''}`}>TANSS</div>
                  <div onClick={() => {this.setState({view: 'weclapp'})}} className={`progress-item ${this.state.view === 'weclapp' ? 'active' : ''}`}>WeClapp</div>
                  <div onClick={() => {this.setState({view: 'mail'})}} className={`progress-item ${this.state.view === 'mail' ? 'active' : ''}`}>E-Mail</div>
                </div>
                { this.state.view === 'register' ? 
                  <div className="field-wrapper">
                    <label htmlFor="company">Firma</label>
                    <input type="text" id="company" name="company" placeholder="Musterfirma GmbH &amp; Co. KG" />
                    <label htmlFor="firstName">Vorname</label>
                    <input type="text" id="firstName" name="firstName" placeholder="Max" />
                    <label htmlFor="lastName">Nachname</label>
                    <input type="text" id="lastName" name="lastName" placeholder="Mustermann" />
                    <label htmlFor="email">E-Mail</label>
                    <input type="text" id="email" name="email" placeholder="max@musterfirma.de" autoComplete="email" />
                    <label htmlFor="password">Passwort</label>
                    <div className="password-wrapper">
                      <input type="password" id="password" name="password" placeholder="abc1234" />
                      <div className="eye" onMouseDown={() => document.querySelector('#password').type = 'text'} onMouseUp={() => document.querySelector('#password').type = 'password'}></div>
                    </div>
                  </div>
                : null }

                { this.state.view === 'tanss' ? 
                  <div className="field-wrapper">
                    <label htmlFor="TANSS_HOST">TANSS-Host</label>
                    <input type="text" id="TANSS_HOST" name="TANSS_HOST" placeholder="tanss.musterdomain.de" />
                    <label htmlFor="TANSS_ERP_KEY">JSON Web Token (ERP-Schnittstelle)</label>
                    <input type="text" id="TANSS_ERP_KEY" name="TANSS_ERP_KEY" placeholder="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJFUlAiLCJleHAiOjE2MjUxMzQxMjAsInR5cGUiOiJFUlAifQ.tpC2cWRXDfVGRFEYWGwi3FLH-O6f39xIs52-qnM8vJ4" />
                    <label htmlFor="TANSS_USERNAME">TANSS Benutzername</label>
                    <input type="text" id="TANSS_USERNAME" name="TANSS_USERNAME" placeholder="max@musterfirma.de" autoComplete="username" />
                    <label htmlFor="TANSS_PASSWORD">TANSS Passwort</label>
                    <input type="password" id="TANSS_PASSWORD" name="TANSS_PASSWORD" placeholder="123456" />
                    <label htmlFor="TANSS_OTP">TANSS 2FA-Token</label>
                    <input type="text" id="TANSS_OTP" name="TANSS_OTP" placeholder="" inputMode="numeric" autoComplete="one-time-code" />
                  </div>
                : null }

                { this.state.view === 'weclapp' ? 
                  <div className="field-wrapper">
                    <label htmlFor="WECLAPP_HOST">WeClapp-Host</label>
                    <input type="text" id="WECLAPP_HOST" name="WECLAPP_HOST" placeholder="firma.weclapp.com" />
                    <label htmlFor="WECLAPP_API_KEY">WeClapp-API-Key</label>
                    <input type="text" id="WECLAPP_API_KEY" name="WECLAPP_API_KEY" placeholder="c190f73a-e4e3-4d7c-97fd-76405df7b801" />
                  </div>
                : null }

                { this.state.view === 'mail' ? 
                  <div className="field-wrapper">
                    <label htmlFor="SMTP_HOST">SMTP-Host</label>
                    <input type="text" id="SMTP_HOST" name="SMTP_HOST" placeholder="smtp.musterdomain.de" />
                    <label htmlFor="SMTP_USERNAME">SMTP-Benutzer</label>
                    <input type="text" id="SMTP_USERNAME" name="SMTP_USERNAME" placeholder="max@musterdomain.de" />
                    <label htmlFor="SMTP_PASSWORD">SMTP-Passwort</label>
                    <div className="password-wrapper">
                      <input type="password" id="SMTP_PASSWORD" name="SMTP_PASSWORD" placeholder="lol123" />
                      <div className="eye" onMouseDown={() => document.querySelector('#SMTP_PASSWORD').type = 'text'} onMouseUp={() => document.querySelector('#SMTP_PASSWORD').type = 'password'}></div>
                    </div>
                    <label htmlFor="EMAIL_FROM">Absender-Adresse</label>
                    <input type="text" id="EMAIL_FROM" name="EMAIL_FROM" placeholder="max@musterdomain.de" />
                  </div>
                : null }  


                <div className="button-wrapper">
                  { views.indexOf(this.state.view) !== 0 && false ? 
                    <button onClick={() => this.back()} className="back">Zurück</button>
                  : null }
                  { views.indexOf(this.state.view) !== (views.length - 1) ?
                    <button onClick={() => this.next()} className="next">Weiter</button>
                  : null }
                  { views.indexOf(this.state.view) === (views.length - 1) ?
                    <button onClick={() => this.next()} className="next">OK!</button>
                  : null }
                </div>
                <div className="help">Fragen oder Probleme? <a href="https://t2w.codemeta.de" className="support-button">Support</a></div>
              </div>
          </div>
        </div>
      );
  }
}

export default Install;

import { Component } from 'react';
import './main.scss';
import logo from '../../assets/Element 3.svg';
import home from '../../assets/home.svg';
import folder from '../../assets/folder.svg';
import config from '../../assets/config.svg';
import profile from '../../assets/profile.svg';
import person from '../../assets/person.svg';
import persons from '../../assets/persons.svg';

import Dashboard from './Dashboard/Dashboard';
import Invoices from './Invoices/Invoices';
import Data from './Data/Data';
import Config from './Config/Config';
import User from './User/User';
import Users from './Users/Users';


class Main extends Component {
  constructor (props) {
    super(props);
    this.state = {
      view: 'dashboard',
      user: JSON.parse(localStorage.getItem('user'))
    }
  }
  render () {
      return (
        <div className="Main">
          <div className="sidebar">
            <img src={logo} className="logo" alt="Tanss2WeClapp" />
            <div className={`menu-item ${this.state.view === 'dashboard' ? 'active' : ''}`} onClick={() => this.setState({view: 'dashboard'})} title="Dashboard" ><img src={home} alt="Dashboard" /></div>
            <div className={`menu-item ${this.state.view === 'invoices' ? 'active' : ''}`} onClick={() => this.setState({view: 'invoices'})} title="Rechnungen" ><img src={folder} alt="Rechnungen" /></div>
            <div className={`menu-item ${this.state.view === 'data' ? 'active' : ''}`} onClick={() => this.setState({view: 'data'})} title="Stammdaten" ><img src={person} alt="Stammdaten" /></div>
            <div className={`menu-item ${this.state.view === 'config' ? 'active' : ''}`} onClick={() => this.setState({view: 'config'})} title="Einstellungen" ><img src={config} alt="Konfiguration" /></div>
            {this.state.user.role === "admin" ? <div className={`menu-item ${this.state.view === 'users' ? 'active' : ''}`} onClick={() => this.setState({view: 'users'})} title="Benutzerverwaltung" ><img id="usercontrol-icon" src={persons} alt="Benutzerverwaltung" /></div> : <></>}
            <div className="profile" onClick={() => this.setState({view: 'user'})}><img src={profile} alt="Konto" /></div>
          </div>
          { this.state.view === 'dashboard' ?
            <Dashboard status={this.props.status} />
          : null }
          { this.state.view === 'invoices' ?
            <Invoices />
          : null }
          { this.state.view === 'data' ?
            <Data />
          : null }
          { this.state.view === 'config' ?
            <Config />
          : null }
          { this.state.view === 'users' ?
            <Users />
          : null }
          { this.state.view === 'user' ?
            <User />
          : null }
        </div>
      );
  }
}

export default Main;

import { Component } from 'react';
import './status.scss';
import { state } from '../../api/api'

class Status extends Component {
  constructor (props) {
    super(props);
    this.state = {
      list: {
        tanssAPI: {
          name: "TANSS API",
          status: "offline",
          details: "Benötigt zum Übertragen der Rechnungsnummern."
        },
        weclappAPI: {
          name: "WeClapp API",
          status: "offline",
          details: "Zum Schreiben der Rechnungen."
        },
        admin: {
          name: "Benutzer Service",
          status: "offline",
          details: "Zum Login im Webinterface."
        },
        emailService: {
          name: "E-Mail Service",
          status: "offline",
          details: "Zum Versenden von Benachrichtigungen."
        }
      }
    }
  }

  componentDidMount () {
    state().then(res => {
      if (res.data && res.data.services) {
        Object.entries(res.data.services).forEach(entry => {
          const [key, value] = entry;
          if(this.state.list[key] !== undefined) {
            let list = this.state.list
            list[key].status = value
            this.setState({list})
          }
        })
      }
    })
  }

  render () {
    let items = Object.entries(this.state.list).map(entry => {
      const [key, value] = entry;
      return (
        <li key={key}>
          <span className={`${value.status} icon`} title={value.status}></span>
          <div>
            <span className="short">{value.name}</span>
            <span className="details">{value.details}</span>
          </div>
          <span className={`${value.status} status`}>{value.status === 'ok' ? 'Online' : 'Fehler'}</span>
        </li>
      )
    })
    return (
      <div className="Status tile">
        <div className="header">
          <h3>Systemstatus</h3>
        </div>
        <ul className="status-list">
          {items}
        </ul>
      </div>
    );
  }
}

export default Status;

import { Component } from 'react';
import './user.scss';
import illustration from '../../../assets/login-pic.svg'
import logo from '../../../assets/Logo.svg'
import { changePassword } from '../../../api/api';
import NotificationManager from 'react-notifications/lib/NotificationManager';

class User extends Component {
  constructor (props) {
    super(props);
    this.state = {}
    const user = localStorage.getItem('user')
    this.state.user = JSON.parse(user)
  }

  async save(e) {
    e.preventDefault()
    if (this.state.newPassword1 !== this.state.newPassword2) {
      document.querySelectorAll('#new-password-1, #new-password-2').forEach(elem => {
        elem.classList.add('invalid')
        elem.setCustomValidity('Die Passwörter stimmen nicht überein.')
        elem.reportValidity()
      })
      return
    }
    changePassword(this.state.newPassword1).then(() => {
      NotificationManager.success('Passwort geändert', 'Aktion erfolgreich', 5000)
    }).catch(e => {
      NotificationManager.error(e.message, 'Aktion fehlgeschlagen', 10000)
    })
  }

  async logout () {
    localStorage.clear()
    window.location.reload()
  }

  render () {
    return (
      <div className="User">
        <div className="user-image">
          <img src={illustration} alt="" />
          <div className="powered-by">powered by <img src={logo} alt="Codemeta" /></div>
        </div>
        <div className="user-form">
          <form className="user-wrapper" onSubmit={(e) => {this.save(e)}}>
            <h1>{this.state.user.firstName} {this.state.user.lastName}</h1>
            <label htmlFor="new-password-1">Neues Passwort</label>
              <input type="password" className="password" id="new-password-1" name="new-password-1" autoComplete="new-password" placeholder="abc1234" onChange={(e) => {this.setState({newPassword1: e.target.value})}} />
              <label htmlFor="new-password-2">Passwort wiederholen</label>
              <input type="password" className="password" id="new-password2" name="new-password2" autoComplete="new-password" placeholder="abc1234" onChange={(e) => {this.setState({newPassword2: e.target.value})}} />
              <button onClick={(e) => {this.save(e)}} className="change-password">Passwort ändern</button>
              <div className="logout">Fremder Rechner? <button href="#" onClick={(e) => {e.preventDefault(); this.logout()}} className="logout-switch">Ausloggen</button></div>
          </form>
        </div>
      </div>
    );
  }
}

export default User;

import { Component } from 'react';
import Messages from '../../../components/Messages/Messages';
import Status from '../../../components/Status/Status';
import Version from '../../../components/Version/Version';
import Welcome from '../../../components/Welcome/Welcome';
import './dashboard.scss';

class Dashboard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      view: 'dashboard'
    }
  }
  render () {
      return (
        <div className="Dashboard">
          <Welcome />
          <Version status={this.props.status} />
          <Messages />
          <Status />
        </div>
      );
  }
}

export default Dashboard;

import axios from 'axios'
import { NotificationManager } from 'react-notifications';
const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/v1' : `${window.location.origin}/api/v1`

export function state() {
  return axios.get(`${url}/status`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  })
}

export async function refreshTokens() {
  const refreshToken = localStorage.getItem('refreshToken')
  if (!refreshToken) {
    throw new Error('Kein Refresh-Token')
  }
  return fetch(`${url}/auth/refresh-tokens`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      refreshToken
    })
  }).then(res => {
    return res.json()
  }).then(res => {
    localStorage.setItem("accessToken", res.access.token)
    localStorage.setItem("reloginAt", res.access.expires)
    localStorage.setItem("refreshToken", res.refresh.token)
    localStorage.setItem("refreshAt", res.refresh.expires)
    return res
  })
}

export function getLogs(level = 'info', page = 1) {
  return fetch(`${url}/logs?sortBy=createdAt:desc&level=${level}&limit=30&page=${page}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    return res.json()
  })
}

export function getInvoices(page = 1, limit = 30, filter) {
  return fetch(`${url}/invoices?sortBy=createdAt:desc&page=${page}&limit=${limit}${filter ? `&status=${filter}` : ''}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    return res.json()
  })
}

export function loadInvoices() {
  return fetch(`${url}/invoices`, {
    method: 'head',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      return
    }
    throw new Error(res.json())
  })
}

export function getReleases(page = 1, limit = 30, filter, blocked = false, searchString = null) {
  return fetch(`${url}/releases/search?sortBy=createdAt:desc&page=${page}&limit=${limit}${filter ? `&code=${filter}` : ''}&blocked=${blocked}${searchString ? `&search=${searchString}` : ''}`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    return res.json()
  })
}

export function getUsers(page = 1, limit = 30, filter, role = 'user') {
  return fetch(`${url}/users?sortBy=createdAt:desc&page=${page}&limit=${limit}${filter ? `&code=${filter}` : ''}&role=${role}`, {
    method:'get',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    return res.json()
  })
}

export function createUser(email, password, firstName, lastName, role) {
  return fetch(`${url}/users`, {
    method:'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'email' : email,
      'password': password,
      'firstName' : firstName,
      'lastName' : lastName,
      'role' : role
    })
  }).then(res => {
    if (res.status === 201) {
      NotificationManager.success(' User wurde erzeugt', 'Aktion erfolgreich', 2000)
      return res
    }
    return res.json().then(res => {
      NotificationManager.error(res.message, 'Fehler beim Erzeugen des Users', 10000)
    })
  })
}

export function deleteUser(id) {
  return fetch(`${url}/users/${id}`, {
    method: 'delete',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      NotificationManager.success('User gelöscht', 'Aktion erfolgreich', 3000)
      return res
    }
    return res.json().then(res => {
      NotificationManager.error(res.message, 'Fehler beim Löschen des Users', 5000)
    })
  })
}

export async function changeUser(user) {
  return fetch(`${url}/users/${user.id}`, {
    method: 'PATCH',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'email' : user.email,
      'password': user.password,
      'firstName' : user.firstName,
      'lastName' : user.lastName,
      'role' : user.role
    })
  }).then(res => {
    if (res.status === 200) {
      NotificationManager.success(' Benutzer wurde geändert', 'Aktion erfolgreich', 2000)
      return res
    }
    return res.json().then(res => {
      NotificationManager.error(res.message, 'Fehler beim Erzeugen des Users', 10000)
    })
  })
}

export function getSettings() {
  return fetch(`${url}/setting`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    return res.json()
  })
}

export function getTaxes() {
  return fetch(`${url}/taxes`, {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    return res.json()
  })
}

export function generateInvoice(id) {
  return fetch(`${url}/invoices/${id}/generate`, {
    method: 'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      return
    }
    return res.json()
  }).then(res => {
    if (res) throw new Error(res.message)
  })
}

export function deleteInvoice(id) {
  return fetch(`${url}/invoices/${id}`, {
    method: 'delete',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      return
    }
    return res.json()
  }).then(res => {
    if (res) throw new Error(res.message)
  })
}

export function addToOrder(id) {
  return fetch(`${url}/invoices/${id}/order`, {
    method: 'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      return
    }
    return res.json()
  }).then(res => {
    if (res) throw new Error(res.message)
  })
}

export function createOrder(id) {
  return fetch(`${url}/invoices/${id}/createOrder`, {
    method: 'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      return
    }
    return res.json()
  }).then(res => {
    if (res) throw new Error(res.message)
  })
}

export function syncContacts(force = false, full = false) {
  return fetch(`${url}/contacts/sync?force=${force}&full=${full}`, {
    method: 'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      NotificationManager.success('Kontakte werden synchronisiert', 'Das dauert ein paar Minuten', 2000)
      return
    }
    if (res.status === 409) {
      NotificationManager.success('Kontakte werden bereits synchronisiert', 'Warten Sie bitte.', 2000)
      return
    } 
    return res.json().then(res => {
      NotificationManager.error(res.message, 'Fehler beim Synchronisieren der Kontakte', 10000)
    })
  })
}

export function syncCustomers(force = false, full = false) {
  return fetch(`${url}/customers/sync?force=${force}&full=${full}`, {
    method: 'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      NotificationManager.success('Kunden werden synchronisiert', 'Das dauert ein paar Minuten', 2000)
      return
    }
    if (res.status === 409) {
      NotificationManager.success('Kunden werden bereits synchronisiert', 'Warten Sie bitte.', 2000)
      return
    }
    return res.json().then(res => {
      NotificationManager.error(res.message, 'Fehler beim Synchronisieren der Kunden', 10000)
    })
  })
}

export function syncCustomer(customerNumber, force = false) {
  return fetch(`${url}/customers/sync/${customerNumber}?force=${force}`, {
    method: 'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    return res.json().then(json => {
      if (res.status === 200) {
        NotificationManager.success('Kunde synchronisiert', 'Aktion erfolgreich', 2000)
      } else {
        NotificationManager.error(json.message, 'Fehler beim Synchronisieren der Kunden', 10000)
        throw (json)
      }
      return json
    })
  })
}

export function deleteAllReleases() {
  return fetch(`${url}/releases`, {
    method: 'delete',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      NotificationManager.success('Alle Anfragen gelöscht', 'Erledigt', 2000)
      return
    }
    return res.json().then(res => {
      NotificationManager.error(res.message, 'Fehler beim Löschen', 10000)
    })
  })
}



export function freeRelease(id) {
  return fetch(`${url}/releases/${id}`, {
    method: 'PATCH',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      return
    }
    throw new Error(res.json())
  })
}

export function denyRelease(id) {
  return fetch(`${url}/releases/${id}`, {
    method: 'delete',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  }).then(res => {
    if (res.status === 204) {
      return
    }
    throw new Error(res.json())
  })
}

export function update() {
  return fetch(`${url}/setting/update`, {
    method: 'post',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  })
}


// INSTALL

export function register(obj) {
  return fetch(`${url}/auth/register`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
}

export function tanss(obj) {
  return fetch(`${url}/setting/tanss`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    },
    body: JSON.stringify(obj)
  })
}

export function weclapp(obj) {
  return fetch(`${url}/setting/weclapp`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    },
    body: JSON.stringify(obj)
  })
}

export function smtp(obj) {
  return fetch(`${url}/setting/smtp`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    },
    body: JSON.stringify(obj)
  })
}

// Login

export function login(obj) {
  return fetch(`${url}/auth/login`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
}

export function forgot(obj) {
  return fetch(`${url}/auth/forgot-password`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
}

export async function reset(token, password) {
  return fetch(`${url}/auth/reset-password?token=${token}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'password': password
    })
  })
}

export async function changePassword(password) {
  const user = JSON.parse(localStorage.getItem('user'))
  return fetch(`${url}/users/${user.id}`, {
    method: 'PATCH',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      'password': password
    })
  }).then(res => {
    if (res.status === 200) {
      return
    }
    return res.json()
  }).then(res => {
    if (res) throw new Error(res.message)
  })
}

// Config

export function setSettings(view, obj) {
  return fetch(`${url}/setting/${view}`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    },
    body: JSON.stringify(obj)
  })
}
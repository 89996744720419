import { Component } from 'react';
import './welcome.scss';
import logo from '../../assets/logo_lang.svg'

class Welcome extends Component {
  constructor (props) {
    super(props);
    this.state = {
    }
    let user = localStorage.getItem('user')
    if (user) this.state.user = JSON.parse(user)
  }
  render () {
      return (
        <div className="Welcome tile">
          <img src={logo} alt="" className="logo" />
          <div className="content">
            <h3>Hallo {this.state.user.firstName}!</h3>
          </div>
        </div>
      );
  }
}

export default Welcome;

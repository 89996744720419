import { Component } from 'react';
import { update } from '../../api/api';
import './version.scss';

class Version extends Component {
  constructor (props) {
    super(props);
    this.state = {
    }
  }

  update () {
    update().then(res => {
      console.log(res)
      if (res.status === 204) {
        document.querySelector('.update').innerText = 'Update wird geladen'
        setTimeout(() => {
          window.location.reload()
        }, 10*1000)
      } else {
        document.querySelector('.errors').innerText = `Fehler ${res.status}: ${res.statusText}`
      }
    })
  }

  render () {
    let validUntil = this.props.status?.license?.validUntil
    if (validUntil) {
      validUntil = new Date(validUntil)
      validUntil = `${validUntil.getDate() < 10 ? '0' + validUntil.getDate() : validUntil.getDate()
                  }.${validUntil.getMonth() + 1 < 10 ? '0' + (validUntil.getMonth() + 1) : validUntil.getMonth() + 1
                  }.${validUntil.getFullYear()}`
    }
    return (
      <div className="Version tile">
        <div className="header">
          <h3>Version</h3>
        </div>
        <div className="wrapper">
          <div className="text">
            <b>Version: </b> {this.props.status?.version}<br />
            <b>Verfügbar: </b> {this.props.status?.latest || "Kann nicht abgerufen werden"}<br /> 
            <b>Lizenz: </b> {this.props.status?.license?.name || "unbekannt"}<br /> 
            <b>Gültig bis: </b> { validUntil || "unbekannt"}<br />
          </div>
          <div className="buttons">
            { this.props.status?.version !== this.props.status?.latest ? 
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a onClick={() => { this.update() }} className="update">Jetzt updaten</a>
            : null}
            { !this.props.status?.license?.licensed ? 
              <a href="https://t2w.itfabrik24.net" target="_blank" rel="noreferrer" className="license">Lizenz kaufen</a>
            : null}
          </div>
        </div>
        <div className="errors"></div>
      </div>
    );
  }
}

export default Version;

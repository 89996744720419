import React, { Component } from 'react';
import Install from './views/Install/Install'
import Login from './views/Login/Login'
import Main from './views/Main/Main'
import logo from './assets/Logo.svg'
import { state, refreshTokens } from './api/api'
import './App.scss';
import './Lists.scss';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
    }
    window.setInterval(() => {
      refreshTokens().catch(e => {
        this.setState({loggedIn: false})
      })
    }, 10*60*1000)
  }

  getState () {
    let self = this
    state().then(res => {
      if (res.data) {
        if(document.querySelector('.load-error')){
          document.querySelector('.load-error').classList.add('hidden')
        }
        self.setState({
          status: res.data.status,
          statusObj: res.data
        })
      }
    }).catch(e => {
      console.debug(e)
      this.setState({status: null, statusObj: {}})
      if(document.querySelector('.load-error')){
        document.querySelector('.load-error').classList.remove('hidden')
      }
      setTimeout(() => {
        this.getState()
      }, 20000)
    })
  }
  componentDidMount () {
    this.getState()
    refreshTokens().then(() => {
      this.setState({loggedIn: true})
    }).catch(e => {
      this.setState({loggedIn: false})
    })
  }
  
  login () {
    this.setState({loggedIn: true})
  }

  render () {
    return (
      <div className="App">
        { this.state.status == null ?
          <div className="load-screen">
            <img src={logo} className="App-logo" alt="logo" />
            <div className="load-error hidden">
              Seltsam... Keine Verbindung zum Backend möglich.
              <span className="instructions">
                Vielleicht wird gerade ein Update installiert. Versuchen Sie es in wenigen Minuten erneut. 
                Starten sie die Container sonst mit <span className="code">docker-compose restart</span> neu.<br />
                Hilft alles nichts? <a href="https://t2w.codemeta.de" className="support-button">Support</a>
              </span>
            </div>
          </div>
        : null}
        { this.state.status === 'install' && (this.state.loggedIn || !this.state.statusObj.admin) ?
          <Install done={() => {this.getState()}} loggedIn={this.state.loggedIn} />
        : null}
        { this.state.status != null && this.state.statusObj.admin !== false && !this.state.loggedIn ? 
          <Login login={() => {this.login()}} />
        : null}
        { this.state.status != null && this.state.status !== 'install' && this.state.loggedIn ?
          <Main status={this.state.statusObj} />
        : null}
        <NotificationContainer/>
      </div>
    );
  }
}

export default App;

import { Component } from 'react';
import Confirmation from '../../../components/Confirmation/Confirmation'
import { getReleases, syncContacts, syncCustomers, freeRelease, denyRelease, syncCustomer, deleteAllReleases } from '../../../api/api';
import './data.scss';
import check from '../../../assets/iconmonstr-check-mark-1.svg';
import deny from '../../../assets/iconmonstr-x-mark-1.svg';
import refresh from '../../../assets/iconmonstr-refresh-3.svg';
import { NotificationManager } from 'react-notifications';

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: null,
      releases: [],
      pageSize: 12,
      blocked: false
    }
  }

  componentDidMount() {
    this.page(1)
  }

  async page(page) {
    if (page < 1) return
    if (this.state.totalPages && page > this.state.totalPages) return

    const releases = await getReleases(page, this.state.pageSize, this.state.filter, this.state.blocked, this.state.searchString)
    this.setState({ releases: releases.results, totalResults: releases.totalResults, totalPages: releases.totalPages, page })
  }

  refresh() {
    return this.page(this.state.page)
  }

  async free(id) {
    freeRelease(id).then(res => {
      NotificationManager.success('Anfrage freigegeben', 'Aktion erfolgreich', 5000)
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler bei der Freigabe', 5000)
    })
  }

  async deny(id) {
    denyRelease(id).then(res => {
      NotificationManager.success('Anfrage blockiert', 'Aktion erfolgreich', 3000)
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler beim Blockieren', 5000)
    })
  }

  async delete(id) {
    denyRelease(id).then(res => {
      NotificationManager.success('Anfrage gelöscht', 'Aktion erfolgreich', 3000)
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler beim Löschen', 5000)
    })
  }

  allChecked() {
    const lis = document.querySelectorAll('li')
    let state = true
    for (const li of lis) {
      if (li.key == null) continue
      state = state && li.classList.contains('checked')
    }
    this.setState({ allChecked: state })
  }

  toggleSelection() {
    const state = this.state.allChecked
    document.querySelectorAll('li').forEach(li => {
      if (state) {
        li.classList.remove('checked')
      } else {
        li.classList.add('checked')
      }
      if (state) {
        li.querySelector('.checkbox').classList.remove('checked')
      } else {
        li.querySelector('.checkbox').classList.add('checked')
      }
    })
    this.setState({ allChecked: !state })
  }

  async freeChecked() {
    const promises = []
    let success = true
    document.querySelectorAll('li.checked').forEach(li => {
      if (li.getAttribute('data-id') == null) return
      promises.push(freeRelease(li.getAttribute('data-id')).catch(e => {
        NotificationManager.error(e.message, 'Fehler bei der Freigabe', 5000)
        success = false
      }))
    })
    await Promise.all(promises)
    if (success) NotificationManager.success('Anfragen freigegeben', 'Aktion erfolgreich', 5000)
    this.refresh()
  }

  async denyChecked() {
    const promises = []
    let success = true
    document.querySelectorAll('li.checked').forEach(li => {
      if (li.getAttribute('data-id') == null) return
      promises.push(denyRelease(li.getAttribute('data-id')).catch(e => {
        NotificationManager.error(e.message, 'Fehler beim Blockieren', 5000)
        success = false
      }))
    })
    await Promise.all(promises)
    if (success) NotificationManager.success('Anfragen blockiert', 'Aktion erfolgreich', 5000)
    this.refresh()
  }

  async deleteChecked() {
    const promises = []
    let success = true
    document.querySelectorAll('li.checked').forEach(li => {
      if (li.getAttribute('data-id') == null) return
      promises.push(denyRelease(li.getAttribute('data-id')).catch(e => {
        NotificationManager.error(e.message, 'Fehler beim Löschen', 5000)
        success = false
      }))
    })
    await Promise.all(promises)
    if (success) NotificationManager.success('Anfragen gelöscht', 'Aktion erfolgreich', 5000)
    this.refresh()
  }

  freeAllCompanies() {
    if (this.state.totalResults > this.state.pageSize) {
      this.setState({
        confirmation: <Confirmation ok={() => {
          syncCustomers(true, true)
          this.setState({ confirmation: null })
        }} phrase={['Alle Kunden freigeben']} cancel={() => this.setState({ confirmation: null })} />
      })
    } else {
      syncCustomers(true, true)
    }
    this.refresh()
  }

  freeAllContacts() {
    if (this.state.totalResults > this.state.pageSize) {
      this.setState({
        confirmation: <Confirmation ok={() => {
          syncContacts(true, true)
          this.setState({ confirmation: null })
        }} phrase={['Alle Kontakte freigeben']} cancel={() => this.setState({ confirmation: null })} />
      })
    } else {
      syncContacts(true, true)
    }
    this.refresh()
  }

  deleteAllReleases() {
    this.setState({
      confirmation: <Confirmation ok={() => {

        deleteAllReleases().then(res => {
        }).catch(e => {
        })
        this.setState({ confirmation: null })
      }} phrase={['Alle Anfragen löschen']} cancel={() => this.setState({ confirmation: null })} />
    })

  }

  syncCustomers(force = false, full = false){
    syncCustomers(force,full).then(res => {
    }).catch(e => {
    })
  }

  syncContacts(force = false, full = false){
    syncContacts(force,full).then(res => {
      this.refresh()
    }).catch(e => {
    })
  }

  async refreshRelease(release) {
    if (release.code === 'CUSTOMER_CHANGED' || release.code === 'CUSTOMER_CREATED' || release.code === 'LEAD_CHANGED' || release.code === 'LEAD_CREATED' || release.code === 'SUPPLIER_CHANGED' || release.code === 'SUPPLIER_CREATED') {
      const update = await syncCustomer(release.number)
      const index = this.state.releases.findIndex(obj => obj.id === release.id)
      this.state.releases[index] = update
      this.setState({ releases: this.state.releases })
    } else if (release.code === 'CONTACT_CHANGED' || release.code === 'CONTACT_CREATED') {
      NotificationManager.error('Kontakte können noch nicht einzeln synchronisiert werden...', 'Entschuldigung', 5000)
    }
    this.refresh()
  }

  render() {
    let items = this.state.releases.map(release => {
      const paths = Object.keys(release.diff).map(path => {
        switch (path) {
          case 'customerNumber': return 'Kdnr.'
          case 'company': return 'Name'
          case 'company2': return 'Namenszusatz'
          case 'website': return 'Website'
          case 'phone': return 'Telefon'
          case 'addresses': return 'Adresse'
          case 'email': return 'E-Mail'
          case 'fax': return 'Fax'
          case 'firstName': return 'Vorname'
          case 'lastName': return 'Nachname'
          case 'mobilePhone1': return 'Mobil'
          case 'tags': return 'Schlagworte'
          case 'bankAccounts': return 'Bankkonten'
          case 'leadStatus': return 'Lead-Status'
          case 'personRole': return 'Rolle'
          case 'contacts': return 'Kontakte'
          case 'parentPartyId': return 'übergeordnete Firma'
          case 'invoiceAddressId': return 'Rechnungsadresse'
          case 'salutation': return 'Anrede'
          default: return path
        }
      }).join(', ')
      return (
        <li key={release.id} id={release.id} data-id={release.id} className={release.possible ? '' : 'impossible'}>
          <span className={`checkbox`} onClick={(evt) => {
            if (evt.target.classList.contains('checked')) {
              evt.target.classList.remove('checked')
            } else {
              evt.target.classList.add('checked')
            }
            if (evt.target.closest('li').classList.contains('checked')) {
              evt.target.closest('li').classList.remove('checked')
            } else {
              evt.target.closest('li').classList.add('checked')
            }
            this.allChecked()
          }}></span>
          <span className="release-number">{release.number}</span>
          <span className="release-details">{release.details}</span>
          <span className="release-paths" title={paths}>{paths}</span>
          <div className={`release-diffs c100 p${Math.round(Object.keys(release.diff).length / 7 * 100)}`} title={`${Object.keys(release.diff).length} Änderungen`}>
            <span>{Object.keys(release.diff).length}</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <div className="release-reason" title={JSON.stringify({ 'Änderungen': release.diff, 'Prüfung': release.validation }, null, 2)}>
            {release.code === 'CUSTOMER_CREATED' ? 'Kunde angelegt' : null}
            {release.code === 'CUSTOMER_CHANGED' ? 'Kunde geändert' : null}
            {release.code === 'LEAD_CREATED' ? 'Interessent angelegt' : null}
            {release.code === 'LEAD_CHANGED' ? 'Interessent geändert' : null}
            {release.code === 'SUPPLIER_CREATED' ? 'Lieferant angelegt' : null}
            {release.code === 'SUPPLIER_CHANGED' ? 'Lieferant geändert' : null}
            {release.code === 'CONTACT_CREATED' ? 'Kontakt angelegt' : null}
            {release.code === 'CONTACT_CHANGED' ? 'Kontakt geändert' : null}
          </div>
          <span className="refresh-release" onClick={(evt) => { this.refreshRelease(release) }} title="Prüfen"><img src={refresh} alt="Prüfen" /></span>
          <span className="free-release" onClick={(evt) => { this.free(release.id) }} title="Freigeben"><img src={check} alt="Freigeben" /></span>
          <span className="deny-release" onClick={(evt) => { if (!release.blocked) this.deny(release.id); else this.delete(release.id) }} title={release.blocked ? "Löschen" : "Blockieren"}><img src={deny} alt={release.blocked ? "Löschen" : "Blockieren"} /></span>
        </li>
      )
    })
    return (
      <div className="Data">
        <div className="heading">
          Freigabeanfragen
        </div>
        <div className="actions">
          <div className="dropdown-button">
            <button className="dropdown-title" onMouseDown={evt => {
              if (evt.target.closest('button') === document.activeElement) {
                setTimeout(() => evt.target.closest('button').blur(), 50)
              }
            }}>
              <span className="bold">Laden</span>
              <span className="description">und Anfragen erzeugen</span>
            </button>
            <div className="dropdown-buttons">
              <button onClick={(evt) => { evt.target.blur(); syncCustomers(); this.refresh() }}>Firmen laden</button>
              <button onClick={(evt) => { evt.target.blur(); syncContacts(); this.refresh() }}>Kontakte laden</button>
            </div>
          </div>
          <div className="dropdown-button">
            <button className="dropdown-title" onMouseDown={evt => {
              if (evt.target.closest('button') === document.activeElement) {
                setTimeout(() => evt.target.closest('button').blur(), 50)
              }
            }}>
              <span className="bold">Aktionen</span>
              <span className="description">für markierte Einträge</span>
            </button>
            <div className="dropdown-buttons">
              <button onClick={(evt) => { evt.target.blur(); this.freeChecked() }}>Markierte freigeben</button>
              <button onClick={(evt) => { evt.target.blur(); this.state.blocked ? this.deleteChecked() : this.denyChecked() }}> {this.state.blocked ? "Markierte löschen" : "Markierte blockieren"}</button>
            </div>
          </div>
          <div className="dropdown-button">
            <button className="dropdown-title" onMouseDown={evt => {
              if (evt.target.closest('button') === document.activeElement) {
                setTimeout(() => evt.target.closest('button').blur(), 50)
              }
            }}>
              <span className="bold">Weitere Aktionen</span>
              <span className="description">für ALLE Einträge</span>
            </button>
            <div className="dropdown-buttons">
              <button onClick={(evt) => { evt.target.blur(); this.syncCustomers(false, true)}}>Alle Firmen laden</button>
              <button onClick={(evt) => { evt.target.blur(); this.syncContacts(false, true)}}>Alle Kontakte laden</button>
              <button onClick={(evt) => { evt.target.blur(); this.freeAllCompanies() }}>Alle Firmen freigeben</button>
              <button onClick={(evt) => { evt.target.blur(); this.freeAllContacts() }}>Alle Kontakte freigeben</button>
              <button onClick={(evt) => { evt.target.blur(); this.deleteAllReleases() }}>Alle Anfragen löschen</button>
            </div>
          </div>

          <div className="dropdown-button search-button">
            <button className="dropdown-title" >
              <input id="search" type="text" placeholder="Suchen" className="searchBar" onChange={(evt) => { this.setState({ searchString: document.getElementById("search").value }, () => this.page(1)) }} />
            </button>
          </div>

        </div>
        <div className="list-header">
          <li>
            <span className={`checkbox ${this.state.allChecked ? 'checked' : ''}`} onClick={(evt) => this.toggleSelection()}></span>
            <span className="release-details">Name</span>
            <span className="release-paths" title="Felder, die sich ändern werden">Felder</span>
            <div className="dropdown-button release-reason" title="Freizugebender Prozess">
              <button onMouseDown={evt => {
                if (evt.target === document.activeElement) {
                  setTimeout(() => evt.target.blur(), 100)
                  this.setState({ filter: null, blocked: false }, () => this.page(1))
                }
              }}>
                {!this.state.filter&& this.state.blocked !== true ? 'Ereignis' : null}
                {this.state.filter === 'CUSTOMER_CREATED' ? 'Neuer Kunde' : null}
                {this.state.filter === 'CUSTOMER_CHANGED' ? 'Kunde geändert' : null}
                {this.state.filter === 'LEAD_CREATED' ? 'Neuer Interessent' : null}
                {this.state.filter === 'LEAD_CHANGED' ? 'Interessent geändert' : null}
                {this.state.filter === 'CONTACT_CREATED' ? 'Neuer Kontakt' : null}
                {this.state.filter === 'CONTACT_CHANGED' ? 'Kontakt geändert' : null}
                {this.state.filter === 'SUPPLIER_CREATED' ? 'Neuer Lieferant' : null}
                {this.state.filter === 'SUPPLIER_CHANGED' ? 'Lieferant geändert' : null}
                {this.state.blocked === true ?  'Blockiert' : null}
              </button>
              <div className="dropdown-buttons">
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'CUSTOMER_CREATED', blocked: false }, () => this.page(1)) }}>Kunde angelegt</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'CUSTOMER_CHANGED', blocked: false }, () => this.page(1)) }}>Kunde geändert</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'LEAD_CREATED', blocked: false }, () => this.page(1)) }}>Interessent angelegt</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'LEAD_CHANGED', blocked: false }, () => this.page(1)) }}>Interessent geändert</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'CONTACT_CREATED', blocked: false }, () => this.page(1)) }}>Kontakt angelegt</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'CONTACT_CHANGED', blocked: false }, () => this.page(1)) }}>Kontakt geändert</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'SUPPLIER_CREATED', blocked: false }, () => this.page(1)) }}>Lieferant angelegt</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ filter: 'SUPPLIER_CHANGED', blocked: false }, () => this.page(1)) }}>Lieferant geändert</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({ blocked: true, filter: null }, () => this.page(1)) }}>Blockiert</button>
              </div>
            </div>
            <span className="do-release">Aktion</span>
          </li>
        </div>
        <ul className="list">
          {items}
        </ul>
        <div className="navigation">
          <span className="text">
            Einträge {1 + (this.state.page - 1) * this.state.pageSize} bis {
              (this.state.page) * this.state.pageSize < this.state.totalResults ? (this.state.page) * this.state.pageSize : this.state.totalResults
            } von {this.state.totalResults}
          </span>
          <span className="prev-page" onClick={() => this.page(this.state.page - 1)}></span>
          <span className="next-page" onClick={() => this.page(this.state.page + 1)}></span>
        </div>
        {this.state.confirmation}
      </div>
    );
  }
}

export default Data;

import { Component } from 'react';
import './switchInput.scss';

class SwitchInput extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: false,
      fresh: true
    }
  }

  componentDidMount () {
    this.setState({value: !!this.props.value})
  }

  componentDidUpdate () {
    if (this.state.fresh && this.props.value) {
      this.setState({value: !!this.props.value, fresh: false})
    }
  }

  toggle () {
    this.setState({value: !this.state.value}, () => {
      this.props.onChange({
        target: {
          id: this.props.id,
          value: this.state.value
        }
      })
    })
  }

  render () {
    return (
      <div className={`SwitchInput ${this.state.value ? 'active' : 'inactive'}`} onClick={() => this.toggle()}>
        <input id={this.props.id} name={this.props.name} type="hidden" />
      </div>
    );
  }
}

export default SwitchInput;
import { Component } from 'react';
// import Invoice from '../../../components/Invoice/Invoice';
import kebap from '../../../assets/iconmonstr-menu-11.svg';
// import deny from '../../../assets/iconmonstr-x-mark-1.svg';
import './invoices.scss';
import { getInvoices, generateInvoice, loadInvoices, addToOrder, createOrder, deleteInvoice } from '../../../api/api'
import NotificationManager from 'react-notifications/lib/NotificationManager';

class Invoices extends Component {
  constructor (props) {
    super(props);
    this.state = {
      pages: null,
      invoices: [],
      pageSize: 12,
      filter: 'RECEIPT'
    }
  }

  componentDidMount () {
    this.page(1)
  }

  async page (page) {
    if (page < 1) return
    if (this.state.totalPages && page > this.state.totalPages) return
    const invoices = await getInvoices(page, this.state.pageSize, this.state.filter)
    this.setState({ invoices: invoices.results, totalResults: invoices.totalResults, totalPages: invoices.totalPages, page })
  }
  
  allChecked () {
    const lis = document.querySelectorAll('li')
    let state = true
    for (const li of lis) {
      if (li.key == null) continue
      state = state && li.classList.contains('checked')
    }
    this.setState({allChecked: state})
  }

  toggleSelection () {
    const state = this.state.allChecked
    document.querySelectorAll('li').forEach(li => {
      if (state) {
        li.classList.remove('checked')
      } else {
        li.classList.add('checked')
      }
      if (state) {
        li.querySelector('.checkbox').classList.remove('checked')
      } else {
        li.querySelector('.checkbox').classList.add('checked')
      }
    })
    this.setState({allChecked: !state})
  }

  async generateChecked () {
    const promises = []
    let success = true
    document.querySelectorAll('li.checked').forEach(li => {
      if (li.getAttribute('data-id') == null) return
      promises.push(generateInvoice(li.getAttribute('data-id')).catch(e=> {
        NotificationManager.error(e.message, 'Fehler beim Erzeugen der Rechnungen', 5000)
        success = false
      }))
    })
    await Promise.all(promises)
    if (success) NotificationManager.success('Rechnungen erzeugt', 'Aktion erfolgreich', 5000)
    this.page(this.state.page)
  }

  async deleteChecked () {
    let success = true
    document.querySelectorAll('li.checked').forEach(li => {
      if (li.getAttribute('data-id') == null) return
      deleteInvoice(li.getAttribute('data-id')).catch(e=> {
        NotificationManager.error(e.message, 'Fehler beim Löschen der Rechnungen', 5000)
        success = false
      })
    })
    if (success) NotificationManager.success('Rechnungen gelöscht', 'Aktion erfolgreich', 5000)
    this.page(this.state.page)
    this.refresh()
  }

  generateInvoice (id) {
    generateInvoice(id).then(res => {
      NotificationManager.success('Rechnung erzeugt', 'Aktion erfolgreich', 5000)
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler beim Erzeugen der Rechnung', 5000)
    })
  }

  deleteInvoice (id) {
    deleteInvoice(id).then(res => {
      NotificationManager.success('Beleg gelöscht', 'Aktion erfolgreich', 5000)
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler beim Löschen des Belegs', 5000)
    })
  }

  addToOrder (id) {
    addToOrder(id).then(res => {
      NotificationManager.success('Auftrag ergänzt', 'Aktion erfolgreich', 5000)
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler beim Ergänzen des Auftrags', 5000)
    })
  }

  createOrder (id) {
    createOrder(id).then(res => {
      NotificationManager.success('Auftrag erzeugt', 'Aktion erfolgreich', 5000)
      this.refresh()
    }).catch(e => {
      NotificationManager.error(e.message, 'Fehler beim Erzeugen des Auftrags', 5000)
    })
  }

  next () {
    if (this.state.page === (Math.ceil((this.state.list.length / this.state.pageLength)) - 3) || (this.state.list.length / this.state.pageLength) < 3) {
      if (!this.state.apiPages || this.state.apiPage < this.state.apiPages) {
        this.loadLogs()
      }
    }
    this.setState({page: this.state.page < ((this.state.list.length / this.state.pageLength) - 1) ? this.state.page + 1 : this.state.page})
  }

  refresh () {
    return this.page(this.state.page)
  }

  render () {
    let items = this.state.invoices.map(invoice => {
      const date = new Date(invoice.date)
      return (
        <li key={invoice.id} data-id={invoice.id}>
          <span className={`checkbox`} onClick={(evt) => {
            if (evt.target.classList.contains('checked')) {
              evt.target.classList.remove('checked')
            } else {
              evt.target.classList.add('checked')
            }
            if (evt.target.closest('li').classList.contains('checked')) {
              evt.target.closest('li').classList.remove('checked')
            } else {
              evt.target.closest('li').classList.add('checked')
            }
            this.allChecked()
          }}></span>
          <span className="invoice-customer">{invoice.customer?.company}</span>
          <span className="invoice-id" title={`Beleg #${invoice.receiptID}${invoice.invoiceNumber ? `, Rechnung ${invoice.invoiceNumber}` : ''}${invoice.orderNumber ? `, Auftrag ${invoice.orderNumber}` : ''}`}>Beleg #{invoice.receiptID}{invoice.invoiceNumber ? `, Rechnung ${invoice.invoiceNumber}` : null}{invoice.orderNumber ? `, Auftrag ${invoice.orderNumber}` : null}</span>
          <span className={`invoice-amount`} title="Wert der Rechnung">{invoice.netAmountTANSS} €</span>
          <div className="invoice-status" title={JSON.stringify(invoice.diff, null, 2)}>
            {!invoice.invoiceID && !invoice.orderNumber ? 'TANSS-Beleg' : null}
            {!invoice.invoiceID && invoice.orderNumber ? 'Auftrag' : null}
            {invoice.invoiceID ? (invoice.status === 'BOOKED' ? '' : 'PRES-') + 'Rechnung' : null}
          </div>
          <div className="invoice-date">
            {`${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
            }.${date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
            }.${date.getFullYear()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
            }:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`}
          </div>
          { invoice.orderNumber ? <div className="generate-invoice" onClick={() => this.addToOrder(invoice.id)}>Auftrag ergänzen</div>
          : <div className="generate-invoice" onClick={() => this.generateInvoice(invoice.id)}>Rechnung erzeugen</div> }
          <span className="invoice-options" title="Weitere Aktionen">
            <button onMouseDown={evt => {
              if (evt.target.closest('button') === document.activeElement) {
                setTimeout(() => evt.target.closest('button').blur(), 50)
              }
            }}><img src={kebap} alt="Weiteres" /></button>
            <div className="dropdown-buttons">
              <button onClick={(evt) => {evt.target.blur(); this.createOrder(invoice.id); }}>Auftrag erzeugen</button>
              <button onClick={(evt) => {evt.target.blur(); this.deleteInvoice(invoice.id); }}>Beleg löschen</button>
              { invoice.orderNumber ? <button onClick={(evt) => {evt.target.blur(); this.generateInvoice(invoice.id) }}>Rechnung schreiben</button> : null}
            </div>
          </span>
        </li>
      )
    })
    return (
      <div className="Invoices">
        <div className="heading">
          Rechnungen
        </div>
        <div className="actions">
          <div className="dropdown-button">
            <button className="dropdown-title" onMouseDown={evt => {
              if (evt.target.closest('button') === document.activeElement) {
                setTimeout(() => evt.target.closest('button').blur(), 50)
              }
            }}>
              <span className="bold">Laden</span>
              <span className="description">aus TANSS</span>
            </button>
            <div className="dropdown-buttons">
              <button onClick={(evt) => {evt.target.blur(); loadInvoices().then(() => this.refresh()) }}>Belege abfragen</button>
            </div>
          </div>
          <div className="dropdown-button">
            <button className="dropdown-title" onMouseDown={evt => {
              if (evt.target.closest('button') === document.activeElement) {
                setTimeout(() => evt.target.closest('button').blur(), 50)
              }
            }}>
              <span className="bold">Aktionen</span>
              <span className="description">für markierte Einträge</span>
            </button>
            <div className="dropdown-buttons">
              <button onClick={(evt) => {evt.target.blur(); this.generateChecked(); }}>Rechnungen schreiben</button>
              <button onClick={(evt) => {evt.target.blur(); this.deleteChecked(); }}>Belege löschen</button>
            </div>
          </div>
        </div>
        <div className="list-header">
          <li>
            <span className={`checkbox ${this.state.allChecked ? 'checked' : ''}`} onClick={(evt) => this.toggleSelection()}></span>
            <span className="invoice-customer">Name</span>
            <span className="invoice-id" title="Zugehörige Buchungen">Buchungen</span>
            <span className="invoice-amount" title="Wert der Rechnung">Wert</span>
            <div className="dropdown-button invoice-status" title="Freizugebender Prozess">
              <button onMouseDown={evt => {
                if (evt.target === document.activeElement) setTimeout(() => evt.target.blur(), 100)
              }}>
                { !this.state.filter ? 'Status' : null }
                { this.state.filter === 'RECEIPT' ? 'Belege' : null }
                { this.state.filter === 'NEW' ? 'PRES' : null }
                { this.state.filter === 'ADDED_TO_ORDER' ? 'Auf Auftrag' : null }
                { this.state.filter === 'BOOKED' ? 'verbucht' : null }
              </button>
              <div className="dropdown-buttons">
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({filter: 'RECEIPT'}, () => this.page(1))}}>Belege</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({filter: 'NEW'}, () => this.page(1))}}>PRES-Rechnungen</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({filter: 'ADDED_TO_ORDER'}, () => this.page(1))}}>Auf Auftrag</button>
                <button className="filter-item" onClick={(evt) => { evt.target.blur(); this.setState({filter: 'BOOKED'}, () => this.page(1))}}>verbuchte Rechnungen</button>
              </div>
            </div>
            <span className="invoice-date">Datum</span>
            <span className="generate-invoice">Aktion</span>
            <span className="invoice-options"></span>
          </li>
        </div>
        <ul className="list">
          {items}
        </ul>
        <div className="navigation">
          <span className="text">
            Einträge {1 + (this.state.page - 1) * this.state.pageSize} bis {
            (this.state.page) * this.state.pageSize < this.state.totalResults ? (this.state.page) * this.state.pageSize : this.state.totalResults
            } von {this.state.totalResults}
          </span>
          <span className="prev-page" onClick={() => this.page(this.state.page - 1)}></span>
          <span className="next-page" onClick={() => this.page(this.state.page + 1)}></span>
        </div>
      </div>
    );
  }
}

export default Invoices;
